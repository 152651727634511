
export const getBonoControl = (val) => {

    switch (val) {
        case 1:
            return "Por cantidad"
            break;
        case 2:
            return "Por fecha"
            break;
        case 3:
            return "Doble control"
            break;
    
        default:
            break;
    }
    
}

export const getBonoEstatus = (val) => {

    switch (val) {
        case 0:
            return "Inactivo"
            break;
        case 1:
            return "Activo"
            break;
    
        default:
            break;
    }
    
}
