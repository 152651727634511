import axios from "axios";
import { getData } from "../servicios";

export const cargarStorage = () =>{

    let array_productos=[]
    let array_bebidas=[]
    let array_postres=[]
    let array_pasillos=[]
    let array_categorias=[]

    axios.all([
        getData("categories"),
        getData("allies")
      ])
      .then(axios.spread((res1,res2) => {


        res1.data.map((categoria)=>{

            categoria.pasillos.map((pasillo)=>{

                pasillo.bebidas.map((bebida)=>{
                    
                    array_bebidas.push(bebida)

                })

                pasillo.postres.map((postre)=>{
                    
                    array_postres.push(postre)

                })

                pasillo.productos.map((producto)=>{

                    array_productos.push(producto)

                })

                delete pasillo['productos']

                array_pasillos.push(pasillo)

            })

            delete categoria["pasillos"]
            array_categorias.push(categoria)

        })

        console.log("data actualizada")

        localStorage.setItem('categories',JSON.stringify(array_categorias))
        localStorage.setItem('halls',JSON.stringify(array_pasillos))
        localStorage.setItem('bebidas',JSON.stringify(array_bebidas))
        localStorage.setItem('postres',JSON.stringify(array_postres))
        localStorage.setItem('allies',JSON.stringify(res2.data))
        localStorage.setItem('products',JSON.stringify(array_productos))
        
    })).catch((err)=>{

        console.log(err)

    });

}

export const getLocalItem = (key) => {

    return JSON.parse(localStorage.getItem(key))
    
}
