import React, { Component,Fragment } from 'react'
import { postData,putData } from '../../../servicios'
import empty from '../../../assets/images/empty.jpg'

export class ModalEspeciales extends Component {
    constructor(props){
        super(props)
        this.state={
            name:props.param.name,
            desc:props.param.desc,
            //preview:props.param.image
        }
        this.handleChange=this.handleChange.bind(this)
    }

    handleChange(e){
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    handleFile=(e)=>{

        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                preview: reader.result
            });
        }
        reader.readAsDataURL(e.target.files[0])
    }

    submit=(e)=>{
        e.preventDefault()
        if(this.props.param.id){
            this.editar()
        }else{
            this.crear()
        }
        this.refs.btn.setAttribute("disabled", "disabled");
    }

    async crear(){

        const json={
            category:{
                name:this.state.name,
                desc:this.state.desc,
                pri:77,
            }
        }

        if(this.state.preview){
            json.category.image=this.state.preview
        }else{
            json.category.image=empty
        }

        try{
            await postData("categories",json)
            this.props.cargar()
            this.props.close()
        }catch(err){
            console.log(err)
        }

    }

    async editar(){
        const json={
            category:{
                name:this.state.name,
                desc:this.state.desc,
                image:this.state.preview,
                pri:77
            }
        }

        try {
            await putData("categories",this.props.param.id,json)
            this.props.cargar()
            this.props.close()
        } catch (err) {
            console.log(err)
        }

    }



    render() {

        let preview
        if(this.state.preview){
            preview=<img src={this.state.preview} alt="preview" className="center"/>
        }else{
            preview=<img src={empty} alt="preview" className="center"/>
        }

        return (
            <Fragment>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">Categorías Especiales</h5>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.submit}>

                        {preview}
                        <div className="form-group">
                            <label htmlFor="image" className="col-form-label">Escoge una imagen :</label>
                            <input className="form-control" name="image" type="file" onChange={this.handleFile}  accept="image/x-png" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name" className="col-form-label" >Nombre :</label>
                            <input type="text" name="name" className="form-control" onChange={this.handleChange} value={this.state.name || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="desc" className="col-form-label" >Descripción :</label>
                            <textarea rows="4" name="desc" className="form-control" onChange={this.handleChange} value={this.state.desc || ''} required />
                        </div>
                        <div className="modal-footer">
                            <button ref='btn' type="submit" className="btn btn-secondary" >Guardar</button>
                        </div>
                    </form>
                </div>

            </Fragment>
        )
    }
}

export default ModalEspeciales
