import React, { Component,Fragment } from 'react'
import { postData,putData } from '../../../servicios'
import empty from '../../../assets/images/vacio.png'
import { ChromePicker } from 'react-color';

export class ModalSaldo extends Component {

    constructor(props){
        super(props)

        this.state={
            saldo:props.param.saldo,
        }
       
        this.handleChange=this.handleChange.bind(this)

    }

    handleChange(e){
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    submit= async (e)=>{
        e.preventDefault()
        this.refs.btn.setAttribute("disabled", "disabled");
        try {
            await postData("d27519671362f93244877d7bb42e0b7e/?email="+this.props.param.email+"&saldo="+this.state.saldo,null)
            this.props.cargar()
            this.props.close()
        } catch (err) {
            console.log(err)
        }
       
    }

    render() {

        let preview
        if(this.state.preview){
            preview=<img src={this.state.preview} alt="preview" className="center"/>
        }else{
            preview=<img src={empty} alt="preview" className="center"/>
        }

        return (
            <Fragment>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">Categoria</h5>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.submit}>

                        <div className="form-group">
                            <label htmlFor="pri" className="col-form-label" >Saldo :</label>
                            <input type="number" min="0"  name="saldo" className="form-control" onChange={this.handleChange} value={this.state.saldo || ''} required />
                        </div>
 
                        <div className="modal-footer">
                            <button ref='btn' type="submit" className="btn btn-secondary" >Guardar</button>
                        </div>
                    </form>
                </div>

            </Fragment>
        )
    }
}

export default ModalSaldo