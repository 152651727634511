import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import { getData,deleteData, postData } from '../../../servicios';
import ModalBono from './ModalBono';
import ReactExport from "react-data-export";
import { getTodayDate } from '../../../funciones/momentEspanol';
import { transformToDateTime } from '../../../funciones/general';
import { getBonoControl,getBonoEstatus } from '../../../funciones/bonos';
import Swal from 'sweetalert2';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class Bono extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: [],
            searchInput: "",
            filteredData: [],
            loading:true,
        };
        this.globalSearch=this.globalSearch.bind(this)
        this.cargarTabla=this.cargarTabla.bind(this)
        this.eliminar=this.eliminar.bind(this)
    }

    onOpenModal = (param) => {
        this.setState({ 
            param: param,
            open:true
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    globalSearch(e) {
        let keys=["name"]
        this.setState({
            searchInput: e.target.value
        },()=>{
            let { searchInput, data } = this.state;
            let filteredData = data.filter(value => {
                return (
                    keys.reduce((acum,key)=> acum || value[key].toString().toLowerCase().includes(searchInput.toLowerCase()),false)
                );
            })
            this.setState({ filteredData:filteredData });
        })
    };

    async cargarTabla(){

        const response=await getData("promos-on")
        const json=response['data']

        this.setState({
            data:json,
            filteredData:json,
            loading:false,
        })

    }

    async componentDidMount(){
        this.cargarTabla()
    }

    async eliminar(id){
        if(window.confirm("Eliminar Bono?")){
            try {
                const response=await deleteData("bono",id)
                this.cargarTabla()
            } catch (err) {
                console.log(err)
            }
        }
    }

    activarBono = async (bono) => {

        Swal.fire({
            title: 'Deseas activar el bono?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si'
        }).then(async (result) => {

            if (result.value) {

                const json={
                    id:bono.id,
                    con:1
                }

                try {
                    const response=await postData("edit-promo",json,{})
                    this.cargarTabla()
                } catch (err) {
                    console.log(err)
                }
              
                
            }
        })
    
    }

    cancelarBono = async (bono) => {

        Swal.fire({
            title: 'Deseas desactivar el bono?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si'
        }).then(async (result) => {

            if (result.value) {

                const json={
                    id:bono.id,
                    con:0
                }

                try {
                    const response=await postData("edit-promo",json,{})
                    this.cargarTabla()
                } catch (err) {
                    console.log(err)
                }
              
                
            }
        })
    
    }


    render() {

        const { filteredData,open,param } = this.state;

        const list=filteredData.map((value,i)=>{

            return(
                <tr key={value.id}>
                    <td>
                        {value.name}
                    </td>
                    <td>
                        {value.valor}
                    </td>
                    <td>
                        {value.cantidad}
                    </td>
                    <td>
                        {getBonoControl(value.tipo)}
                    </td>
                    {/*
                    <td>
                        {transformToDateTime(value.fecha_i)}
                    </td>
                    <td>
                        {transformToDateTime(value.fecha_f)}
                    </td>
                    */}
                    <td>
                        {getBonoEstatus(value.status)}
                    </td>
                    <td>
                        {
                            value.status==0?
                            <span onClick={()=> this.activarBono(value)}>
                                <i className="fa fa-play-circle pointer"  style={{ width: 35, fontSize: 20, padding: 11,color:'#138f9b' }}></i>
                            </span>
                            :<></>
                        }
                        {
                            value.status==1?
                            <span onClick={()=> this.cancelarBono(value)}>
                                <i className="fa fa-stop-circle pointer" style={{ width: 35, fontSize: 20, padding: 11, color: '#b8112d' }}></i>
                            </span>
                            :<></>

                        }
                        
                    </td>
                </tr>
            )
            
        })

        return (
            <Fragment>

                <Breadcrumb title="Bonos"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">

                                    <div className="btn-popup d-flex">
                                        <input placeholder="Buscar" onChange={this.globalSearch} className="form-control input-search mr-auto" />
                                        {/*
                                            this.state.loading?
                                            <></>
                                            :
                                            <ExcelFile 
                                                filename={"Lista de codigos("+getTodayDate()+")"}
                                                element={<button type="button" className="btn btn-secondary pull-right mr-3">Exportar Excel</button>}>
                                                <ExcelSheet data={filteredData} name="Bonos">
                                                    <ExcelColumn label="Nombre" value="name"/>
                                                </ExcelSheet>
                                            </ExcelFile>
                                        */}
                                        <button type="button" className="btn btn-secondary pull-right" onClick={this.onOpenModal} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Agregar Bono</button>
                                    </div>

                                    <Modal open={open} onClose={this.onCloseModal} >
                                        <ModalBono param={param} close={this.onCloseModal} cargar={this.cargarTabla}/>
                                    </Modal>

                                    <div className="user-status table-responsive latest-order-table">
                                        <table className="table table-bordernone">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Codigo</th>
                                                    <th scope="col">Valor</th>
                                                    <th scope="col">Cantidad restante</th>
                                                    <th scope="col">Control</th>
                                                    {/*
                                                    <th scope="col">Inicio</th>
                                                    <th scope="col">Fin</th>
                                                    */}
                                                    <th scope="col">Estatus</th>                                      
                                                    <th scope="col">Accion</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

export default Bono