import React, { Component } from 'react'
import { getTotalNormal } from '../../../servicios'
import { getDiaMesEspanolHuertaCongelado } from '../../../funciones/momentEspanol'

class Huerta extends Component {

    constructor(props){
        super(props)
        
    }


    render() {

        const {productos,match}=this.props

        if(productos.length>0){

            return (
                <div>
                    
                    <h5 className="f-w-600 center tac">Huerta orgánica</h5>

                    <div className="row">
                        <div className="col-3">
                            <strong>Producto</strong>
                        </div>
                        <div className="col-3">
                            <strong>Cantidad</strong>                       
                        </div>
                        <div className="col-3">
                            <strong>Aliado</strong>                       
                        </div>
                        <div className="col-3">
                            <strong>Costo</strong>                       
                        </div>
                    </div>

                    <br/>

                    {
                        productos.map((item, index) => {
                            return (

                                <div key={index}>

                                    <div className="row" >
                                        <div className="col-3">
                                            {item.producto}
                                        </div>
                                        <div className="col-3">
                                            {item.amount} 
                                        </div>
                                        <div className="col-3">
                                            {item.a_name} 
                                        </div>
                                        <div className="col-3">
                                            ${(item.total)}
                                        </div>
                                    </div>

                                </div>
                            
                            )
                        })
                    }

                    <hr/>

                    <div className="row">
                        <div className="col-4">
                            <strong>Entrega:</strong>
                        </div>
                        <div className="col-4">
                            {getDiaMesEspanolHuertaCongelado(productos[0].fecha_i)} a las {productos[0].hora}                 
                        </div>
                    </div>

                    {
                        !match?
                        <div className="row">
                            <div className="col-4">
                                <strong>Delivery:</strong>
                            </div>
                            <div className="col-4">
                                ${productos[0].delivery}             
                            </div>
                        </div>
                        :''
                    }

                    <div className="row">
                        <div className="col-4">
                            <strong>Total:</strong>
                        </div>
                        <div className="col-4">
                            ${getTotalNormal(productos,4,match)}             
                        </div>
                    </div>

                    <br/>
                 
                </div>
            )

        }else{
            return ''
        }
        
    }
}

export default Huerta