import React, { Component, Fragment } from 'react';
import { postData, putData } from '../../../servicios';
import empty from '../../../assets/images/vacio.png';
import { ChromePicker } from 'react-color';
import { cargarStorage } from '../../../funciones/cargarDatos';

export class ModalCategoria extends Component {
    constructor(props) {
        super(props);

        if (!props.param.color) {
            this.state = {
                pri: props.param.pri,
                name: props.param.name,
                desc: props.param.desc,
                //preview:props.param.image,
                color: '#fff',
                tipo: props.param.tipo,
                back_on: props.param.back_on,
                ext: props.param.ext,
            };
        } else {
            this.state = {
                pri: props.param.pri,
                name: props.param.name,
                desc: props.param.desc,
                //preview:props.param.image,
                color: props.param.color,
                tipo: props.param.tipo,
                jerarquia: props.param.jerarquia,
                coment: props.param.if_coment,
                back_on: props.param.back_on,
                ext: props.param.ext,
            };
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    handleFile = (e) => {
        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                preview: reader.result,
            });
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    submit = (e) => {
        e.preventDefault();
        if (this.props.param.id) {
            this.editar();
        } else {
            this.crear();
        }
        this.refs.btn.setAttribute('disabled', 'disabled');
    };

    async crear() {
        const json = {
            category: {
                name: this.state.name,
                desc: this.state.desc,
                pri: this.state.pri,
                color: this.state.color,
                tipo: this.state.tipo,
                if_coment: this.state.coment,
                jerarquia: this.state.jerarquia,
                back_on: this.state.back_on,
                ext: this.state.ext,
            },
        };

        if (this.state.preview) {
            json.category.image = this.state.preview;
        } else {
            json.category.image = empty;
        }

        try {
            await postData('categories', json);
            cargarStorage();
            this.props.cargar();
            this.props.close();
        } catch (err) {
            console.log(err);
        }
    }

    async editar() {
        const json = {
            category: {
                name: this.state.name,
                desc: this.state.desc,
                pri: this.state.pri,
                color: this.state.color,
                tipo: this.state.tipo,
                if_coment: this.state.coment,
                jerarquia: this.state.jerarquia,
                back_on: this.state.back_on,
                ext: this.state.ext,
            },
        };

        if (this.state.preview) {
            json.category.image = this.state.preview;
        }

        try {
            await putData('categories', this.props.param.id, json);
            cargarStorage();
            this.props.cargar();
            this.props.close();
        } catch (err) {
            console.log(err);
        }
    }

    handleChangeComplete = (color) => {
        this.setState({ color: color.hex });
    };

    componentDidMount() {
        if (!this.props.param.id) {
            this.setState({
                tipo: 0,
                cities: 1,
                ext: 0,
            });
        }
    }

    render() {
        let preview;
        if (this.state.preview) {
            preview = <img src={this.state.preview} alt="preview" className="center" />;
        } else {
            preview = <img src={empty} alt="preview" className="center" />;
        }

        return (
            <Fragment>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">
                        Categoria
                    </h5>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.submit}>
                        {preview}
                        <div className="form-group">
                            <label htmlFor="image" className="col-form-label">
                                Escoge una imagen :
                            </label>
                            <input
                                className="form-control"
                                name="image"
                                type="file"
                                onChange={this.handleFile}
                                accept="image/x-png"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name" className="col-form-label">
                                Nombre :
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.name || ''}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="desc" className="col-form-label">
                                Descripción :
                            </label>
                            <textarea
                                rows="4"
                                name="desc"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.desc || ''}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="pri" className="col-form-label">
                                Prioridad :
                            </label>
                            <input
                                type="number"
                                min="1"
                                max="70"
                                name="pri"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.pri || ''}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="tipo" className="col-form-label">
                                Patron de envío :
                            </label>
                            <select
                                name="tipo"
                                onChange={this.handleChange}
                                value={this.state.tipo || ''}
                                className="form-control"
                                required
                            >
                                <option value={0}>Tipo Snack</option>
                                <option value={1}>Tipo Core</option>
                                <option value={3}>Plan Alimenticio</option>
                                <option value={4}>Entrega Los Martes(Huerta Y Congelados)</option>
                                <option value={6}>Cena y fines de semana</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="cities" className="col-form-label">
                                Ciudades de envío :
                            </label>
                            <select
                                name="ext"
                                onChange={this.handleChange}
                                value={this.state.ext || ''}
                                className="form-control"
                                required
                            >
                                <option value={0}>Todas</option>
                                <option value={1}>Bogotá/Chía</option>
                            </select>
                        </div>
                        {this.state.tipo == 1 ? (
                            <div className="form-group">
                                <label htmlFor="coment" className="col-form-label">
                                    Comentario :
                                </label>
                                <select
                                    name="coment"
                                    onChange={this.handleChange}
                                    value={this.state.coment || ''}
                                    className="form-control"
                                    required
                                >
                                    <option value={0}>Deshabilitado</option>
                                    <option value={1}>Habilitado</option>
                                </select>
                            </div>
                        ) : (
                            ''
                        )}

                        <div className="form-group">
                            <label htmlFor="jerarquia" className="col-form-label">
                                Jerarquia :
                            </label>
                            <select
                                name="jerarquia"
                                onChange={this.handleChange}
                                value={this.state.jerarquia || ''}
                                className="form-control"
                                required
                            >
                                <option value={0}>N/A</option>
                                <option value={1}>Huerta Principal</option>
                                <option value={2}>Sub Huerta</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="back_on" className="col-form-label">
                                Color App :
                            </label>
                            <select
                                name="back_on"
                                onChange={this.handleChange}
                                value={this.state.back_on || ''}
                                className="form-control"
                                required
                            >
                                <option value={0}>Desactivado</option>
                                <option value={1}>Activado</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="color" className="col-form-label">
                                Color :
                            </label>
                            <div className="center">
                                <ChromePicker
                                    disableAlpha
                                    color={this.state.color}
                                    onChangeComplete={this.handleChangeComplete}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button ref="btn" type="submit" className="btn btn-secondary">
                                Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </Fragment>
        );
    }
}

export default ModalCategoria;
