import React, { Component } from 'react';
import { getArrayByTipo, isOrdenLocal } from '../../servicios';
import Restaurante from './Secciones/Restaurante';
import Core from './Secciones/Core';
import Plan from './Secciones/Plan';
import Huerta from './Secciones/Huerta';
import Snack from './Secciones/Snack';
import Congelado from './Secciones/Congelado';
import Cena from './Secciones/Cena';
import { HuertaCoreMatchOrden } from '../../funciones/Ordenes';
import { getStatusText } from '../../funciones/general';
import External from './Secciones/External';

class ModalOrder extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { orden } = this.props;
        const productos = orden.productos;
        const snack = getArrayByTipo(productos, 0);
        const core = getArrayByTipo(productos, 1);
        const restaurante = getArrayByTipo(productos, 2);
        const plan = getArrayByTipo(productos, 3);
        const huerta = getArrayByTipo(productos, 4);
        const congelado = getArrayByTipo(productos, 5);
        const cena = getArrayByTipo(productos, 6);

        const match = HuertaCoreMatchOrden(huerta, core);

        const isLocal = isOrdenLocal(orden.ext);

        return (
            <>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">
                        Detalles de la orden
                    </h5>
                </div>

                <div className="modal-body">
                    <h5 className="f-w-600 center tac">Datos Del Cliente</h5>

                    <div className="row">
                        <div className="col-3" />
                        <div className="col-3">
                            <strong>Nombre:</strong>
                        </div>
                        <div className="col-6">
                            <strong>{orden.cname}</strong>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3" />
                        <div className="col-3">
                            <strong>Teléfono:</strong>
                        </div>
                        <div className="col-6">
                            <strong>{orden.tel_c}</strong>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3" />
                        <div className="col-3">
                            <strong>Dirección:</strong>
                        </div>
                        <div className="col-6">
                            <strong>{`${orden.city} - ${orden.dir}`}</strong>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3" />
                        <div className="col-3">
                            <strong>Email:</strong>
                        </div>
                        <div className="col-6">
                            <strong>{orden.email}</strong>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3" />
                        <div className="col-3">
                            <strong>Cédula:</strong>
                        </div>
                        <div className="col-6">
                            <strong>{orden.ci}</strong>
                        </div>
                    </div>

                    <br />

                    {isLocal ? (
                        <>
                            <Snack productos={snack} orden={orden} />
                            <Core productos={core} orden={orden} match={match} />
                            <Restaurante productos={restaurante} orden={orden} />
                            <Plan productos={plan} orden={orden} />
                            <Huerta productos={huerta} orden={orden} match={match} />
                            <Congelado productos={congelado} orden={orden} />
                            <Cena productos={cena} orden={orden} />
                        </>
                    ) : (
                        <External productos={productos} orden={orden} match={match} />
                    )}

                    {orden.desc && (
                        <div className="row mb-2">
                            <div className="col-4">
                                <strong>Comentario adicional:</strong>
                            </div>
                            <div className="col-8">{orden.desc}</div>
                        </div>
                    )}

                    {match && isLocal ? (
                        <div className="row">
                            <div className="col-4">
                                <strong>Delivery(Categorias principales,huerta y congelados):</strong>
                            </div>
                            <div className="col-4">${core[0].delivery}</div>
                        </div>
                    ) : (
                        ''
                    )}

                    {parseFloat(orden.discount) > 0 ? (
                        <>
                            <div className="row">
                                <div className="col-4">
                                    <strong>Valor en carrito:</strong>
                                </div>
                                <div className="col-4">${parseFloat(orden.amount) + parseFloat(orden.discount)}</div>
                            </div>

                            <div className="row">
                                <div className="col-4">
                                    <strong>Descuento por saldo:</strong>
                                </div>
                                <div className="col-4">${orden.discount}</div>
                            </div>
                        </>
                    ) : (
                        ''
                    )}

                    <div className="row">
                        <div className="col-4">
                            <strong>Total pagado:</strong>
                        </div>
                        <div className="col-4">${orden.amount}</div>
                    </div>

                    {orden.pay_method == 3 ? (
                        <div className="row mt-2">
                            <div className="col-4">
                                <strong>Transacción PSE:</strong>
                            </div>

                            <div className="col-4">{getStatusText(orden.mensaje)}</div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </>
        );
    }
}

export default ModalOrder;
