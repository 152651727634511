import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch,Redirect } from 'react-router-dom';
import './index.scss';
import App from './components/app';
import { ScrollContext } from 'react-router-scroll-4';

// Components
import Login from './components/auth/login';
import NotFound from './components/common/NotFound';
import NotAuthRoute from './AuthGuard/NotAutRoute';
import AuthRoute from './AuthGuard/AuthRoute';

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { cargarStorage, getLocalItem } from './funciones/cargarDatos';


const firebaseConfig = {
    apiKey: "AIzaSyDPITzpReb1O0NbK4AYUvVgQY-ZhczaAYI",
    authDomain: "fluio-admin.firebaseapp.com",
    databaseURL: "https://fluio-admin.firebaseio.com",
    projectId: "fluio-admin",
    storageBucket: "fluio-admin.appspot.com",
    messagingSenderId: "123712485822",
    appId: "1:123712485822:web:bddf7aaa1e65c0153be1ef"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
cargarStorage()

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <ScrollContext>
                    <Switch>
                        <NotAuthRoute exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                        <AuthRoute path={`${process.env.PUBLIC_URL}/dashboard`} component={App} />
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={NotFound} />
                        <Route path="*" >
                            <Redirect to="/404" />
                        </Route>
                        

                    {/*    
                        <App>
                            <Route exact path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />
                            <Route exact path={`${process.env.PUBLIC_URL}/maestros/categoria`} component={Categoria} />                 
                        </App>
                        */}     
                        
                        
                        
                    </Switch>
                </ScrollContext>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));


