import React, { Component } from 'react'
import man from '../../../assets/images/dashboard/man.png'
import { getUser } from '../../../servicios'

export class User_panel extends Component {
    constructor(props){
        super(props)
    }
    render() {
        return (
            <div>
                <div className="sidebar-user text-center">
                    <div><img className="img-60 rounded-circle lazyloaded blur-up" src={man} alt="#" />
                    </div>
                    <h6 className="mt-3 f-14">{getUser().name}</h6>
                </div>
            </div>
        )
    }
}

export default User_panel

