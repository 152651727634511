import React, { Component,Fragment } from 'react'
import { postData,putData } from '../../../servicios'
import empty from '../../../assets/images/empty.jpg'

export class ModalAliado extends Component {

    constructor(props){
        super(props)

        if(!props.param.color){
            this.state={
                pri:props.param.pri,
                name:props.param.name,
                desc:props.param.desc,
                tipo: props.param.tipo
            }
        }else{
            this.state={
                pri:props.param.pri,
                name:props.param.name,
                desc:props.param.desc,
                tipo: props.param.tipo
            }
        }

        this.handleChange=this.handleChange.bind(this)


    }

    handleChange(e){
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    handleFile=(e)=>{

        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                preview: reader.result
            });
        }
        reader.readAsDataURL(e.target.files[0])
    }

    submit=(e)=>{
        e.preventDefault()
        if(this.props.param.id){
            this.editar()
        }else{
            this.crear()
        }
        this.refs.btn.setAttribute("disabled", "disabled");
    }

    async crear(){

        const json={
            ally:{
                name:this.state.name,
                desc:this.state.desc,
                pri:this.state.pri,
                tipo: this.state.tipo
            }
        }

        if(this.state.preview){
            json.ally.image=this.state.preview
        }else{
            json.ally.image=empty
        }

        try{
            await postData("allies",json)
            this.props.cargar()
            this.props.close()
        }catch(err){
            console.log(err)
        }

    }

    async editar(){
        const json={
            ally:{
                name:this.state.name,
                desc:this.state.desc,
                pri:this.state.pri,
                tipo: this.state.tipo
            }
        }

        if(this.state.preview){
            json.ally.image=this.state.preview
        }

        try {
            await putData("allies",this.props.param.id,json)
            this.props.cargar()
            this.props.close()
        } catch (err) {
            console.log(err)
        }

    }


    componentDidMount(){

        if(!this.props.param.id){
            this.setState({
                tipo: 0
            })
        }

    }



    render() {

        let preview
        if(this.state.preview){
            preview=<img src={this.state.preview} alt="preview" className="center"/>
        }else{
            preview=<img src={empty} alt="preview" className="center"/>
        }

        return (
            <Fragment>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">Aliado</h5>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.submit}>

                        {preview}
                        <div className="form-group">
                            <label htmlFor="image" className="col-form-label">Escoge una imagen :</label>
                            <input className="form-control" name="image" type="file" onChange={this.handleFile}  accept="image/jpeg" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name" className="col-form-label" >Nombre :</label>
                            <input type="text" name="name" className="form-control" onChange={this.handleChange} value={this.state.name || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="desc" className="col-form-label" >Descripción :</label>
                            <textarea rows="4" name="desc" className="form-control" onChange={this.handleChange} value={this.state.desc || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="pri" className="col-form-label" >Prioridad :</label>
                            <input type="number" min="1" max="70" name="pri" className="form-control" onChange={this.handleChange} value={this.state.pri || ''} required />
                        </div>
                        <div className="modal-footer">
                            <button ref='btn' type="submit" className="btn btn-secondary" >Guardar</button>
                        </div>
                    </form>
                </div>

            </Fragment>
        )
    }
}

export default ModalAliado