import React, { Component } from 'react';
import { getTotalNormal } from '../../../servicios';
import { getDiaMesEspanol } from '../../../funciones/momentEspanol';
import { getTimeRange } from '../../../funciones/general';

class External extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { productos, match } = this.props;

        if (productos.length > 0) {
            return (
                <div>
                    <h5 className="f-w-600 center tac">Productos</h5>

                    <div className="row">
                        <div className="col-3">
                            <strong>Producto</strong>
                        </div>
                        <div className="col-3">
                            <strong>Cantidad</strong>
                        </div>
                        <div className="col-3">
                            <strong>Aliado</strong>
                        </div>
                        <div className="col-3">
                            <strong>Costo</strong>
                        </div>
                    </div>
                    <br />

                    {productos.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className="row">
                                    <div className="col-3">{item.producto}</div>
                                    <div className="col-3">{item.amount}</div>
                                    <div className="col-3">{item.a_name}</div>
                                    <div className="col-3">${item.total}</div>
                                    {item.com ? (
                                        <div className="col-12">
                                            <strong>Comentario:</strong>
                                            {item.com}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <hr />
                            </div>
                        );
                    })}

                    <div className="row">
                        <div className="col-4">
                            <strong>Entrega:</strong>
                        </div>
                        <div className="col-4">{getDiaMesEspanol(productos[0].fecha_i)}</div>
                    </div>

                    {!match ? (
                        <div className="row">
                            <div className="col-4">
                                <strong>Delivery:</strong>
                            </div>
                            <div className="col-8">
                                El envío se realizara a cobro en destino el costo del mismo depende de la empresa de
                                encomienda
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    <div className="row">
                        <div className="col-4">
                            <strong>Total:</strong>
                        </div>
                        <div className="col-4">${getTotalNormal(productos, 1, match)}</div>
                    </div>

                    <br />
                </div>
            );
        } else {
            return '';
        }
    }
}

export default External;
