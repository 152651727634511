import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import ModalCategoria from './ModalCategoria'
import { getData,deleteData } from '../../../servicios';
import ReactExport from "react-data-export";
import { getTodayDate } from '../../../funciones/momentEspanol';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class Categoria extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: [],
            searchInput: "",
            filteredData: [],
            loading:true,
        };
        this.globalSearch=this.globalSearch.bind(this)
        this.cargarTabla=this.cargarTabla.bind(this)
        this.eliminar=this.eliminar.bind(this)
    }

    onOpenModal = (param) => {
        this.setState({ 
            param: param,
            open:true
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    globalSearch(e) {
        let keys=["name","desc","pri"]
        this.setState({
            searchInput: e.target.value
        },()=>{
            let { searchInput, data } = this.state;
            let filteredData = data.filter(value => {
                return (
                    keys.reduce((acum,key)=> acum || value[key].toString().toLowerCase().includes(searchInput.toLowerCase()),false)
                );
            })
            this.setState({ filteredData:filteredData });
        })
    };

    async cargarTabla(){

        const response=await getData("categories")
        const json=response['data']

        this.setState({
            data:json,
            filteredData:json,
            loading:false
        })

    }

    async componentDidMount(){
        this.cargarTabla()
    }

    async eliminar(id){
        if(window.confirm("Eliminar Categoria?")){
            try {
                const response=await deleteData("categories",id)
                console.log(response)
                this.cargarTabla()
            } catch (err) {
                console.log(err)
            }
        }
    }

    exportExcel = (e) => {

        e.preventDefault()
    
    }


    render() {

        const { filteredData,open,param } = this.state;

        const list=filteredData.filter((value)=>value.pri<77).map((value,i)=>{

            return(
                <tr key={value.id}>
                    <td>
                        {value.pri}
                    </td>
                    <td>
                        <img src={value.image} className="t-image"/>
                    </td>
                    <td>
                        {value.name}
                    </td>
                    <td>
                        {value.desc}
                    </td>
                    <td>
                        <span onClick={()=> this.onOpenModal(value)}>
                            <i className="fa fa-pencil pointer"  style={{ width: 35, fontSize: 20, padding: 11,color:'#138f9b' }}></i>
                        </span>
                        <span onClick={()=> this.eliminar(value.id)}>
                            <i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: '#b8112d' }}></i>
                        </span>
                    </td>
                </tr>
            )
            
        })

        return (
            <Fragment>

                <Breadcrumb title="Categorias"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">

                                    <div className="btn-popup d-flex">
                                        <input placeholder="Buscar" onChange={this.globalSearch} className="form-control input-search mr-auto" />

                                        {
                                            this.state.loading?
                                            <></>
                                            :
                                            <ExcelFile 
                                                filename={"Lista de categorias("+getTodayDate()+")"}
                                                element={<button type="button" className="btn btn-secondary pull-right mr-3">Exportar Excel</button>}>
                                                <ExcelSheet data={filteredData.filter((value)=>value.pri<77)} name="Categorias">
                                                    <ExcelColumn label="Prioridad" value="pri"/>
                                                    <ExcelColumn label="Nombre" value="name"/>
                                                    <ExcelColumn label="Descripcion" value="desc"/>
                                                </ExcelSheet>
                                            </ExcelFile>
                                        }
                                   
                                        <button type="button" className="btn btn-secondary pull-right" onClick={this.onOpenModal} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Agregar Categoria</button>
                                    </div>

                                    <Modal open={open} onClose={this.onCloseModal} >
                                        <ModalCategoria param={param} close={this.onCloseModal} cargar={this.cargarTabla}/>
                                    </Modal>

                                    <div className="user-status table-responsive latest-order-table">
                                        <table className="table table-bordernone">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Prioridad</th>
                                                    <th scope="col">Imagen</th>
                                                    <th scope="col">Nombre</th>
                                                    <th scope="col">Descripcion</th>                                                   
                                                    <th scope="col">Accion</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

export default Categoria
