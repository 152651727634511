import React, { Component,Fragment } from 'react'
import { postData,putData,getData, allData } from '../../../servicios'
import empty from '../../../assets/images/empty.jpg'
import TextError from '../../common/TextError'

export class ModalCuentasAliados extends Component {

    constructor(props){
        super(props)
        this.state={
            name:props.param.name,
            email:props.param.email,
            password:props.param.password,
            ally_id:props.param.ally_id,
            aliados:[],
            error:[]
        }
        this.handleChange=this.handleChange.bind(this)
        this.handlePasillo=this.handlePasillo.bind(this)
    }

    handleChange(e){
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }


    handleAliado = (e) => {
        const {value}= e.target
        this.setState({
            ally_id:value,
        })
    }
    

    handlePasillo(e){
        const { name,value}= e.target
        this.setState({
            [name]: value,
            hall: undefined
        },()=>{

            const {categories} =this.state
            const json=categories.filter((item)=>{
                return item.id==value
            })

            this.setState({
                halls:json[0].pasillos
            })

        })
    }

    handleFile=(e)=>{

        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                preview: reader.result
            });
        }
        reader.readAsDataURL(e.target.files[0])
    }

    submit=(e)=>{
        e.preventDefault()
        if(this.props.param.id){
            this.editar()
        }else{
            this.crear()
        }
        
    }

    async crear(){

        this.refs.btn.setAttribute("disabled", "disabled");

        const json={
            manager:{
                name:this.state.name,
                email:this.state.email,
                password:this.state.password,
                ally_id:this.state.ally_id,
            }
        }

        try{
            const req=await postData("new-man",json)

            this.props.cargar()
            this.props.close()
            
            
        }catch(err){
            console.log(err)
            this.refs.btn.removeAttribute("disabled");

            if(err.response.data){

                this.setState({
                    error:err.response.data.errors
                })
                
            }

        }


    }

    async editar(){

        this.refs.btn.setAttribute("disabled", "disabled");

        const json={
            manager:{
                name:this.state.name,
                email:this.state.email,
                password:this.state.password,
                ally_id:this.state.ally_id,
            }
        }

        try {
            await putData("products",this.props.param.id,json)
            this.props.cargar()
            this.props.close()
        } catch (err) {
            console.log(err)
        }

    }


    async componentDidMount(){
        const response=await allData([getData("allies")])
        const jsonAlly=response[0].data

        this.setState({
            aliados:jsonAlly
        },()=>{

            //caso nuevo producto
            if(!this.props.param.id){

                this.setState({
                    ally_id:jsonAlly[0].id
                })

            //caso producto viejo
            }else{


            }

        })

    }

    render() {

        const { aliados,error } = this.state;


        const list_aliados=aliados.map((value)=>{
            return(
                <option key={value.id} value={value.id}>{value.name}</option>
            )
        })
        

        return (
            <Fragment>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">Cuentas Aliados</h5>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.submit}>

                        <div className="form-group">
                            <label htmlFor="name" className="col-form-label" >Nombre :</label>
                            <input type="text" name="name" className="form-control" onChange={this.handleChange} value={this.state.name || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email" className="col-form-label" >Email :</label>
                            <input type="email" name="email" className="form-control" onChange={this.handleChange} value={this.state.email || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="ally_id" className="col-form-label" >Aliado :</label>
                            <select name="ally_id" onChange={this.handleAliado} value={this.state.ally_id || ''} className="form-control"  required>
                                <option  value={""}>N/A</option>
                                {list_aliados}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password" className="col-form-label" >Contraseña :</label>
                            <input type="password" name="password" className="form-control" onChange={this.handleChange} value={this.state.password || ''} required />
                        </div>

                        <div className="modal-footer">
                            <TextError show={error.length>0} text={error.join()}/>
                            <button ref='btn' type="submit" className="btn btn-secondary">Guardar</button>
                        </div>
                    </form>
                </div>

            </Fragment>
        )
    }
}

export default ModalCuentasAliados