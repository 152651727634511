import React, { Component,Fragment } from 'react';
import Breadcrumb from './common/breadcrumb';
import { Tag, Box, Users, DollarSign } from 'react-feather';
import CountUp from 'react-countup';
import CanvasJSReact from '../assets/canvas/canvasjs.react';
import Modal from 'react-responsive-modal';
import { getData,deleteData, putData } from '../servicios';

import { Bar } from 'react-chartjs-2';
import {
    lineOptions
} from '../constants/chartData'
// image impoer
import user2 from '../assets/images/dashboard/user2.jpg';
import user1 from '../assets/images/dashboard/user1.jpg';
import man from '../assets/images/dashboard/man.png';
import user from '../assets/images/dashboard/user.png';
import designer from '../assets/images/dashboard/designer.jpg'
import { Circle } from 'react-feather';
import ModalOrder from './Orden/ModalOrder';
import Swal from 'sweetalert2';
import ReactExport from "react-data-export";
import { getTodayDate } from '../funciones/momentEspanol';
import { textoEntrega } from '../funciones/general';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class Dashboard extends Component {

  constructor(props) {
      super(props);
      this.state = {
          open: false,
          data: [],
          searchInput: "",
          filteredData: [],
          order:{},
          loading:true,
      };
      this.globalSearch=this.globalSearch.bind(this)
      this.cargarTabla=this.cargarTabla.bind(this)

  }

  onOpenModal = (param) => {
      this.setState({
          param: param,
          open:true
      });
  };

  onCloseModal = () => {
      this.setState({ open: false });
  };

  globalSearch(e) {
      let keys=["amount","desc","dir","tel_c","email"]
      this.setState({
          searchInput: e.target.value
      },()=>{
          let { searchInput, data } = this.state;
          let filteredData = data.filter(value => {
              return (
                  keys.reduce((acum,key)=> acum || value[key].toString().toLowerCase().includes(searchInput.toLowerCase()),false)
              );
          })
          this.setState({ filteredData:filteredData });
      })
  };

  async cargarTabla(){

    try {

        const response=await getData("orders")
        const json=response['data']

        this.setState({
            data:json,
            filteredData:json,
            loading:false,
        })
        
    } catch (error) {

        console.log(error)
        
    }

      

  }

  async componentDidMount(){
      this.cargarTabla()
  }

    completarOrden = async (orden) => {

        const { value: option } = await Swal.fire({
            title: 'Deseas Actualizar la orden?',
            icon: 'question',
            input: 'select',
            inputOptions: {
                1: 'En Camino',
                3: 'En Proceso',
                4: 'Completado',
                6: 'Cancelado/Rechazado',
            },
            inputPlaceholder: 'Escoge una opción',
            showCancelButton: true,
            inputValidator: (value) => {
                return new Promise(async (resolve) => {

                    if(value){

                        const json={
                            order:{
                                status: parseInt(value),
                            }
                        }

                        try {
                            const response=await putData("orders",orden.id,json)
                            console.log(response)
                            this.cargarTabla()
                        } catch (err) {
                            console.log(err)
                        }
        
                        resolve()

                    }else{

                        resolve('Debes escoger una opción')

                    }

                    
    
                })
            }
        })
    
    }

    render() {

        const { filteredData,open,param } = this.state;

        const list=filteredData.map((value)=>{

            return(
                <tr key={value.id}>
                    <td>
                        {value.pedido}
                    </td>
                    <td>
                        {value.fecha}
                    </td>
                    <td>
                        {value.amount} $
                    </td>
                    <td>
                        {textoEntrega(value)}
                    </td>
                    <td>
                        {value.dir}
                    </td>
                    <td>
                        {value.cname}
                    </td>
                    <td>
                        {value.tel_c}
                    </td>
                    <td>
                        {value.email}
                    </td>
                    <td>
                        {value.pay_t}
                    </td>
                    <td>
                        {value.estado}
                    </td>
                    <td>
                        <span onClick={()=> this.onOpenModal(value)}>
                            <i className="fa fa-eye pointer"  style={{ width: 35, fontSize: 20, padding: 11,color:'#138f9b' }}></i>
                        </span>
                        <span onClick={()=> this.completarOrden(value)}>
                            <i className="fa fa-check pointer"  style={{ width: 35, fontSize: 20, padding: 11,color:'#138f9b' }}></i>
                        </span>
                    </td>

                </tr>
            )

        })

        return (

            <Fragment>
                <Breadcrumb title="Dashboard" parent="Dashboard" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-md-6 xl-50">
                            <div className="card o-hidden widget-cards">
                                <div className="bg-warning card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-4">
                                            <div className="align-self-center text-center"><DollarSign className="font-warning" /></div>
                                        </div>
                                        <div className="media-body col-8"><span className="m-0">Ganancias</span>
                                            <h3 className="mb-0">$ <CountUp className="counter" end={0} /><small> Este Mes</small></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <div className="card o-hidden  widget-cards">
                                <div className="bg-secondary card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-4">
                                            <div className="align-self-center text-center"><Box className="font-secondary" /></div>
                                        </div>
                                        <div className="media-body col-8"><span className="m-0">Products Vendidos</span>
                                            <h3 className="mb-0"><CountUp className="counter" end={0} /><small> Este Mes</small></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <div className="card o-hidden widget-cards">
                                <div className="bg-primary card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-4">
                                            <div className="align-self-center text-center"><Tag className="font-primary" /></div>
                                        </div>
                                        <div className="media-body col-8"><span className="m-0">Productos sin stock</span>
                                            <h3 className="mb-0"><CountUp className="counter" end={0} /></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <div className="card o-hidden widget-cards">
                                <div className="bg-danger card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-4">
                                            <div className="align-self-center text-center"><Users className="font-danger" /></div>
                                        </div>
                                        <div className="media-body col-8"><span className="m-0">Clientes Registrados</span>
                                            <h3 className="mb-0"><CountUp className="counter" end={0} /></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*
                          <div className="col-xl-6 xl-100">
                              <div className="card">
                                  <div className="card-header">
                                      <h5>Productos Mas Vendidos Del Mes</h5>
                                  </div>
                                  <div className="card-body">
                                      <div className="market-chart">
                                          <Bar data={lineData} options={lineOptions} width={778} height={308} />
                                      </div>
                                  </div>
                              </div>
                          </div>
                        */}

                        <div className="col-xl-12 xl-100">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Últimas Ordenes</h5>
                                </div>
                                <div className="card-body">
                                    <div className="btn-popup d-flex">
                                        {
                                            this.state.loading?
                                            <></>
                                            :
                                            <ExcelFile 
                                                filename={"Ordenes en curso("+getTodayDate()+")"}
                                                element={<button type="button" className="btn btn-secondary pull-right mr-3">Exportar Excel</button>}>
                                                <ExcelSheet data={filteredData} name="Ordenes">
                                                    <ExcelColumn label="Pedido" value="pedido"/>
                                                    <ExcelColumn label="Fecha" value="fecha"/>
                                                    <ExcelColumn label="Monto" value="amount"/>
                                                    <ExcelColumn label="Dirección" value="dir"/>
                                                    <ExcelColumn label="Cliente" value="cname"/>
                                                    <ExcelColumn label="Teléfono" value="tel_c"/>
                                                    <ExcelColumn label="Correo" value="email"/>
                                                    <ExcelColumn label="Método de pago" value="pay_t"/>
                                                    <ExcelColumn label="Estado" value="estado"/>
                                                </ExcelSheet>
                                            </ExcelFile>
                                        }
                                    </div>
                                    <div className="user-status table-responsive latest-order-table">
                                        <table className="table table-bordernone">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Orden ID</th>
                                                    <th scope="col">Fecha y Hora</th>
                                                    <th scope="col">Orden Total</th>
                                                    <th scope="col">Día y hora de entrega</th>
                                                    <th scope="col">Dirección De Envío</th>
                                                    <th scope="col">Cliente</th>
                                                    <th scope="col">No. Contacto</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Método de pago</th>
                                                    <th scope="col">Estado</th>
                                                    <th scope="col">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="col-xl-6 xl-100">
                            <div className="card height-equal">
                                <div className="card-header">
                                    <h5>Productos con menos stock</h5>
                                </div>
                                <div className="card-body">
                                    <div className="user-status table-responsive products-table">
                                        <table className="table table-bordernone">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Details</th>
                                                    <th scope="col">Quantity</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Simply dummy text of the printing</td>
                                                    <td className="digits">1</td>
                                                    <td className="font-primary">Pending</td>
                                                    <td className="digits">$6523</td>
                                                </tr>
                                                <tr>
                                                    <td>Long established</td>
                                                    <td className="digits">5</td>
                                                    <td className="font-secondary">Cancle</td>
                                                    <td className="digits">$6523</td>
                                                </tr>
                                                <tr>
                                                    <td>sometimes by accident</td>
                                                    <td className="digits">10</td>
                                                    <td className="font-secondary">Cancle</td>
                                                    <td className="digits">$6523</td>
                                                </tr>
                                                <tr>
                                                    <td>classical Latin literature</td>
                                                    <td className="digits">9</td>
                                                    <td className="font-primary">Return</td>
                                                    <td className="digits">$6523</td>
                                                </tr>
                                                <tr>
                                                    <td>keep the site on the Internet</td>
                                                    <td className="digits">8</td>
                                                    <td className="font-primary">Pending</td>
                                                    <td className="digits">$6523</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <a href="javascript:void(0)" className="btn btn-primary">Ver Todos</a>
                                    </div>
                                </div>
                            </div>
                        </div>*/}

                    </div>
                </div>

                <Modal open={open} onClose={this.onCloseModal} classNames={{modal:'modal-size'}} >
                    <ModalOrder orden={param} close={this.onCloseModal} cargar={this.cargarTabla}/>
                </Modal>
                
            </Fragment>

        )
    }
}
// javascript:void(0)

export default Dashboard
