import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import { getData, deleteData } from '../../../servicios';
import ReactExport from 'react-data-export';
import { getTodayDate } from '../../../funciones/momentEspanol';
import ModalSaldo from './ModalSaldo';
import { transformToDateTime, transformToNormalDate } from '../../../funciones/general';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class Usuario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: [],
            searchInput: '',
            filteredData: [],
        };
        this.globalSearch = this.globalSearch.bind(this);
        this.cargarTabla = this.cargarTabla.bind(this);
    }

    onOpenModal = (param) => {
        this.setState({
            param: param,
            open: true,
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    globalSearch(e) {
        let keys = ['name', 'email', 'tel'];
        this.setState(
            {
                searchInput: e.target.value,
            },
            () => {
                let { searchInput, data } = this.state;
                let filteredData = data.filter((value) => {
                    return keys.reduce(
                        (acum, key) => acum || value[key].toString().toLowerCase().includes(searchInput.toLowerCase()),
                        false
                    );
                });
                this.setState({ filteredData: this.transformData(filteredData) });
            }
        );
    }

    transformData = (data) => {
        let newData = [];

        data.map((val) => {
            let json = {
                id: val.id,
                name: val.name,
                nickname: val.nickname,
                email: val.email,
                tel: val.tel,
                saldo: val.saldo,
                created_at: transformToNormalDate(val.created_at),
            };

            newData.push(json);
        });

        return newData;
    };

    async cargarTabla() {
        const response = await getData('bdf78ab18cfcefbfad06c28724642e06');
        const json = response['data'];
        console.log(json);

        this.setState({
            data: json,
            filteredData: this.transformData(json),
        });
    }

    async componentDidMount() {
        this.cargarTabla();
    }

    exportExcel = (e) => {
        e.preventDefault();
    };

    render() {
        const { filteredData, open, param } = this.state;

        const list = filteredData.map((value, i) => {
            return (
                <tr key={value.id}>
                    <td>{value.name}</td>
                    <td>{value.nickname}</td>
                    <td>{value.email}</td>
                    <td>{value.tel}</td>
                    <td>{value.saldo}</td>
                    <td>{value.created_at}</td>
                    <td>
                        <span onClick={() => this.onOpenModal(value)}>
                            <i
                                className="fa fa-dollar pointer"
                                style={{ width: 35, fontSize: 20, padding: 11, color: 'green' }}
                            ></i>
                        </span>
                    </td>
                </tr>
            );
        });

        return (
            <Fragment>
                <Breadcrumb title="Usuarios" />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-popup d-flex">
                                        <input
                                            placeholder="Buscar"
                                            onChange={this.globalSearch}
                                            className="form-control input-search mr-auto"
                                        />

                                        <ExcelFile
                                            filename={'Lista de usuarios(' + getTodayDate() + ')'}
                                            element={
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary pull-right"
                                                    onClick={this.exportExcel}
                                                >
                                                    Exportar Excel
                                                </button>
                                            }
                                        >
                                            <ExcelSheet data={filteredData} name="Usuarios">
                                                <ExcelColumn label="Nombre" value="name" />
                                                <ExcelColumn label="Usuario" value="nickname" />
                                                <ExcelColumn label="Correo" value="email" />
                                                <ExcelColumn label="Telefono" value="tel" />
                                                <ExcelColumn label="F. Registro" value="created_at" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </div>

                                    <Modal open={open} onClose={this.onCloseModal}>
                                        <ModalSaldo param={param} close={this.onCloseModal} cargar={this.cargarTabla} />
                                    </Modal>

                                    <div className="user-status table-responsive latest-order-table">
                                        <table className="table table-bordernone">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nombre</th>
                                                    <th scope="col">Usuario</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Telefono</th>
                                                    <th scope="col">Saldo</th>
                                                    <th scope="col">Registro</th>
                                                    <th scope="col">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>{list}</tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Usuario;
