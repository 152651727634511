import {
    Home,
    List,
    Archive,
    Clipboard,
    User
} from 'react-feather';

export const MENUITEMS = [
    {
        path: '/dashboard/home', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
    },
    /**
    {
        path: '/dashboard/users', title: 'Usuarios', icon: User, type: 'link', badgeType: 'primary', active: false
    },
     */
    {
        title: 'Gestión', icon: List, type: 'sub', active: false, children: [
            { path: '/dashboard/maestros/categoria', title: 'Categorias', type: 'link' },
            { path: '/dashboard/maestros/especiales', title: 'Categorias Especiales', type: 'link' },
            { path: '/dashboard/maestros/pasillo', title: 'Pasillo', type: 'link' },
            { path: '/dashboard/maestros/producto', title: 'Producto', type: 'link' },
            { path: '/dashboard/maestros/producto-por-aprobar', title: 'Productos por aprobar', type: 'link' },
            { path: '/dashboard/maestros/producto-sugerido', title: 'Producto Sugerido', type: 'link' },
            { path: '/dashboard/maestros/producto-poco', title: 'Productos por agotarse', type: 'link' },
            { path: '/dashboard/maestros/producto-sin-clave', title: 'Productos sin palabras clave', type: 'link' },
            { path: '/dashboard/maestros/producto-sin-tags', title: 'Productos sin tags', type: 'link' },
            { path: '/dashboard/maestros/producto-sin-halls', title: 'Productos sin pasillos', type: 'link' },
            { path: '/dashboard/maestros/adicional', title: 'Adicional', type: 'link' },
            { path: '/dashboard/maestros/aliado', title: 'Aliados', type: 'link' },
            { path: '/dashboard/maestros/usuario', title: 'Usuarios', type: 'link' },
            { path: '/dashboard/maestros/bono', title: 'Bonos', type: 'link' }
        ]

    },
    {
        title: 'Ordenes', icon: List, type: 'sub', active: false, children: [
            { path: '/dashboard/maestros/ordenesactivas', title: 'Proceso', type: 'link' },
            { path: '/dashboard/maestros/ordenescompletadas', title: 'Completadas', type: 'link' },
            { path: '/dashboard/maestros/ordenescanceladas', title: 'Canceladas', type: 'link' }

        ]

    },
    {
        path: '/dashboard/cuentas-aliados', title: 'Cuentas de aliados', icon: User, type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Reportes', icon: Clipboard, type: 'sub', active: false, children: [
            { path: '/dashboard/finanzas', title: 'Finanzas', type: 'link' },
        ]

    },
    {
        path: '/dashboard/sugerencias', title: 'Sugerencias', icon: Archive, type: 'link', badgeType: 'primary', active: false
    },
]
