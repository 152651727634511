import React, { Component } from 'react'
import { getTotalNormal } from '../../../servicios'

class Cena extends Component {

    constructor(props){
        super(props)
        
    }


    render() {

        const {productos}=this.props

        if(productos.length>0){

            return (
                <div>
                    
                    <h5 className="f-w-600 center tac">Restaurantes</h5>

                    <div className="row">
                        <div className="col-3">
                            <strong>Producto</strong>
                        </div>
                        <div className="col-3">
                            <strong>Cantidades</strong>                       
                        </div>
                        <div className="col-3">
                            <strong>Aliado</strong>                       
                        </div>
                        <div className="col-3">
                            <strong>Costo</strong>                       
                        </div>
                    </div>
                    <br/>

                    {
                        productos.map((item, index) => {
                            return (

                                <div key={index}>

                                    <div className="row" >
                                        <div className="col-3">
                                            {item.producto}  
                                        </div>
                                        <div className="col-3">
                                            <div>
                                                Plato principal: {item.amount}
                                            </div>
                                            {
                                                item.adname ?
                                                <>
                                                    {(item.adname)} (Bebidas:{item.bebidas_n},Postres:{item.postres_n})
                                                </>: ''                                           
                                            }
                                        </div>
                                        <div className="col-3">
                                            {item.a_name} 
                                        </div>
                                        <div className="col-3">
                                            ${(item.total)}
                                        </div>
                                        {
                                            item.com ?
                                                <div className="col-12">
                                                    <strong>Comentario:</strong>
                                                    {item.com}
                                                </div>
                                            :''
                                        }                                      
                                    </div>
                                    <hr/>

                                </div>
                            
                            )
                        })
                    }

                    <div className="row">
                        <div className="col-4">
                            <strong>Entrega:</strong>
                        </div>
                        <div className="col-4">
                            {productos[0].fecha_i} a las {productos[0].hora}                 
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <strong>Delivery:</strong>
                        </div>
                        <div className="col-4">
                            ${productos[0].delivery}              
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <strong>Total:</strong>
                        </div>
                        <div className="col-4">
                            ${getTotalNormal(productos,6)}             
                        </div>
                    </div>

                    <br/>
                 
                </div>
            )

        }else{
            return ''
        }
        
    }
}

export default Cena