import React, { Component,Fragment } from 'react'
import Modal from 'react-responsive-modal';
import Breadcrumb from './common/breadcrumb';
import { getData, getArrayByTipo } from '../servicios';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { calcularPorcentaje, calcularDelivery, calcularGanaciaTotal, calcularFees , calcularTodas } from '../funciones/calculos';
import { excelSanitize } from '../funciones/general';

export class Finanzas extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: [],
            searchInput: "",
            filteredData: [],
            openHora:false,
        };
        this.cargarTabla=this.cargarTabla.bind(this)
    }


    handleChange = (e) => {

        const { name,value}= e.target
        this.setState({
            [name]: value
        })
        
    }
    



    async cargarTabla(e){

        e.preventDefault()

        const response=await getData("orders-report?fi="+this.state.fi+"&ff="+this.state.ff)
        //const response=await getData("completed-orders")
        const json=response['data']

        this.setState({
            data:json,
        })

    }

    render() {

        const { data,open,param,openHora } = this.state;

        const list=data.map((order,indexOrder)=>{

            return(

                order.productos.map((value,i)=>{

                    let por_ganancia=parseFloat(value.a_fee)
                    let rete_fuentes=calcularFees(order,1.50)
                    let rete_ica=calcularFees(order,0.414)
                    let todas=calcularTodas(order,2.99)
                    let iva=calcularPorcentaje(todas,19)
                    let consignado=order.amount_t-rete_fuentes-rete_ica-todas-iva

                    const productos=order.productos

                    let delivery=calcularDelivery(productos)

                    let ganancia=calcularGanaciaTotal(order)

                    let beneficio=ganancia-(rete_fuentes+rete_ica+todas+iva)+delivery

                    return(
                        <>
                        <tr key={i}>
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {order.id}
                                </td>
                                :<></>
                            }
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {order.fecha}
                                </td>
                                :<></>
                            }
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {order.cname}
                                </td>
                                :<></>
                            }
                            <td>
                                {value.producto}
                            </td>
                            <td>
                                {value.amount}
                            </td>
                            <td>
                                {value.a_name}
                            </td>
                            <td>
                                {excelSanitize(value.total)}
                            </td>
                            <td>
                                %{por_ganancia}
                            </td>
                            <td>
                                {excelSanitize(calcularPorcentaje(value.total,por_ganancia).toFixed(2))}
                            </td>
                            <td>
                                {excelSanitize(calcularPorcentaje(value.total,100-por_ganancia).toFixed(2))}
                            </td>
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {excelSanitize(delivery)}
                                </td>
                                :<></>
                            }
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {excelSanitize(order.amount_t)}
                                </td>
                                :<></>
                            }
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {order.pay_t}
                                </td>
                                :<></>
                            }
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {excelSanitize(rete_fuentes.toFixed(2))}
                                </td>
                                :<></>
                            }
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {excelSanitize(rete_ica.toFixed(2))}
                                </td>
                                :<></>
                            }
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {excelSanitize(todas.toFixed(2))}
                                </td>
                                :<></>
                            }    
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {excelSanitize(iva.toFixed(2))}
                                </td>
                                :<></>
                            }
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {excelSanitize(consignado.toFixed(2))}
                                </td>
                                :<></>
                            }   
                            {
                                i==0?
                                <td rowSpan={order.productos.length}>
                                    {excelSanitize(beneficio.toFixed(2))}
                                </td>
                                :<></>
                            }                       
                        </tr>
                        </>
                    )
                })
            )
            
        })

        return (
            <Fragment>

                <Breadcrumb title="Finanzas"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">

                                    <form onSubmit={this.cargarTabla}>

                                        <div className="form-row">

                                            <div className="form-group col-md-4">
                                                <label htmlFor="fi">Fecha de inicio</label>
                                                <input name="fi" type="date" className="form-control" onChange={this.handleChange} required />
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label htmlFor="ff">Fecha de fin</label>
                                                <input name="ff" type="date" className="form-control" onChange={this.handleChange} required />
                                            </div>                                         

                                        </div>

                                        <button className="btn btn-secondary mr-5">
                                            Generar tabla
                                        </button>

                                        {
                                            data.length>0?
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="btn btn-secondary"
                                                table="table-to-xls"
                                                filename="finanzas"
                                                sheet="finanzas"
                                                buttonText="Exportar Excel"
                                            />
                                            :''
                                        }

                                        

                                    </form>
                                             
                                    <div className="user-status table-responsive latest-order-table" style={{height: "600px"}}>
                                        <table className="table table-bordernone " id="table-to-xls">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Pedido</th>
                                                    <th scope="col">fecha</th>
                                                    <th scope="col">Cliente</th>
                                                    <th scope="col">Descripción</th>
                                                    <th scope="col">Cantidad</th>
                                                    <th scope="col">Aliado</th>
                                                    <th scope="col">Costo</th>
                                                    <th scope="col">% de Ganancia</th>
                                                    <th scope="col">Ganancia</th>
                                                    <th scope="col">Pago Aliado</th>
                                                    <th scope="col">Delivery</th>
                                                    <th scope="col">Pago Total</th>
                                                    <th scope="col">Tipo de pago</th>
                                                    <th scope="col">Rete fuentes(1.50%)</th>
                                                    <th scope="col">Rete ica(0.414%)</th>
                                                    <th scope="col">Todas(2.99%+500)</th>
                                                    <th scope="col">IVA(19%)</th>
                                                    <th scope="col">Consignado</th>
                                                    <th scope="col">Beneficio Operativo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

export default Finanzas