import React from 'react'


export default function FormError(props) {
    if(props.show){
        return (
            <p className="text-danger">
                {props.text}
            </p>   
        )
    }else{
        return null
    }
}
