import React, { Component } from 'react'

export class NotFound extends Component {
    render() {
        return (
            <div>
                Not Found
            </div>
        )
    }
}
export default NotFound
