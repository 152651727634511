import React, { Component } from 'react'
import horas from '../../../constants/horasPasillo.json'

export class HoraPasillo extends Component {


    constructor(){
        super()
        this.state={
            list:[],
            open_semana:'',
            open_fin:'',
            close_semana:'',
            close_fin:''
        }
    }

    submit = (e) => {
        e.preventDefault()
        console.log(this.state)


    }

    handleCheck = (e) => {

        const {list}=this.state

        var index = list.indexOf(parseInt(e.target.value));
        if (e.target.checked){
            list.push(e.target.value); // push in array checked value
        }else{
            list.splice(index, 1); // removed in array unchecked value
        }

        this.setState({
            list:list
        })
        
    }


    handleChange=(e)=>{
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    render() {

        const {list}= this.state

        const list_check=horas.map((value,i)=>{

            return (
                <div className="col-md-3" key={i}>
                    <div className="form-check">
                        <input className="form-check-input" onChange={this.handleCheck} type="checkbox" value={value.hora} checked={list.includes(value.hora) ? true : false}/>
                        <label className="form-check-label">
                            {value.hora}
                        </label>
                    </div>
                </div>
            )
        })


        return (
            <div>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">Horarios</h5>
                </div>
                <div className="modal-body">

                    <form onSubmit={this.submit} >

                        <div className="form-group">
                            <label htmlFor="open_semana" className="col-form-label">Lunes a viernes(apertura) :</label>
                            <input className="form-control" name="open_semana" type="time" onChange={this.handleChange}  value={this.state.open_semana || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="close_semana" className="col-form-label">Lunes a viernes(cierre) :</label>
                            <input className="form-control" name="close_semana" type="time" onChange={this.handleChange}  value={this.state.close_semana || ''} required />
                        </div>


                        <div className="form-group">
                            <label htmlFor="open_fin" className="col-form-label" >Sabado y Domingo(apertura) :</label>
                            <input type="text" name="open_fin" type="time" className="form-control" onChange={this.handleChange} value={this.state.open_fin || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="close_fin" className="col-form-label" >Sabado y Domingo(cierre) :</label>
                            <input type="text" name="close_fin" type="time" className="form-control" onChange={this.handleChange} value={this.state.close_fin || ''} required />
                        </div>

                        <div className="modal-footer">
                            <button ref='btn' type="submit" className="btn btn-secondary" >Guardar</button>
                        </div>

                    </form>

                </div>
            </div>
        )
    }
}

export default HoraPasillo
