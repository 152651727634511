import React, { Component,Fragment } from 'react'
import { postData,putData } from '../../../servicios'
import empty from '../../../assets/images/empty.jpg'
import { getNewDate, transformToDateTime } from '../../../funciones/general'
import DateTimePicker from 'react-datetime-picker';
import TextError from '../../common/TextError'

export class ModalBono extends Component {

    constructor(props){
        super(props)

        this.state={
            name:props.param.name,
            status:props.param.status ? props.param.status : 0,
            tipo:props.param.tipo ? props.param.tipo :1,
            valor: props.param.valor,
            cantidad: props.param.cantidad ? props.param.cantidad : 100,
            fecha_i: props.param.fecha_i ? transformToDateTime(props.param.fecha_i) : getNewDate(),
            fecha_f: props.param.fecha_f ? transformToDateTime(props.param.fecha_f) : getNewDate(),
            error:''
        }
        

        this.handleChange=this.handleChange.bind(this)
        this.handleNumberSelect=this.handleNumberSelect.bind(this)


    }

    handleChange(e){
        const { name,value,type}= e.target

        this.setState({
            [name]: type=='number'? parseInt(value) : value
        })
    }


    handleNumberSelect(e){
        const { name,value}= e.target

        this.setState({
            [name]: parseInt(value)
        })
    }

    handleFile=(e)=>{

        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                preview: reader.result
            });
        }
        reader.readAsDataURL(e.target.files[0])
    }

    submit=(e)=>{
        e.preventDefault()
        if(this.props.param.id){
            this.editar()
        }else{
            this.crear()
        }
        
    }

    async crear(){

        this.refs.btn.setAttribute("disabled", "disabled");

        const json={
            promo:{
                name:this.state.name,
                status: this.state.status,
                tipo: this.state.tipo,
                valor: this.state.valor,
                cantidad: this.state.cantidad,
                fecha_i: this.state.fecha_i+" 00:00:01",
                fecha_f: this.state.fecha_f+" 23:59:59",

            }
            
        }

        try{
            await postData("create-promo",json)
            this.props.cargar()
            this.props.close()
        }catch(err){
            console.log(err)
            this.refs.btn.removeAttribute("disabled");

            if(err.response.data){

                this.setState({
                    error:err.response.data.errors
                })
                
            }
        }

    }

    async editar(){

        this.refs.btn.setAttribute("disabled", "disabled");

        const json={
            ally:{
                name:this.state.name,
            }
        }

        if(this.state.preview){
            json.ally.image=this.state.preview
        }

        try {
            await putData("bono",this.props.param.id,json)
            this.props.cargar()
            this.props.close()
        } catch (err) {
            console.log(err)
        }

    }


    componentDidMount(){

    }



    render() {

        const {error} = this.state

        return (
            <Fragment>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">Bono</h5>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.submit}>

                        <div className="form-group">
                            <label htmlFor="name" className="col-form-label" >Codigo :</label>
                            <input type="text" name="name" className="form-control" onChange={this.handleChange} value={this.state.name || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="valor" className="col-form-label" >Valor :</label>
                            <input type="number" name="valor" className="form-control" onChange={this.handleChange} value={this.state.valor || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="cantidad" className="col-form-label" >Cantidad :</label>
                            <input type="number" name="cantidad" className="form-control" onChange={this.handleChange} value={this.state.cantidad || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="status" className="col-form-label" >Estatus :</label>
                            <select name="status" onChange={this.handleNumberSelect} value={this.state.status || ''} className="form-control" required >
                                <option  value={0}>Inactivo</option>
                                <option  value={1}>Activo</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="tipo" className="col-form-label" >Tipo de control :</label>
                            <select type="number" name="tipo" onChange={this.handleNumberSelect} value={this.state.tipo || ''} className="form-control" required >
                                <option  value={1}>Cantidad</option>
                                {/*
                                <option  value={2}>Fecha</option>
                                <option  value={3}>Ambos</option>
                                */}
                            </select>
                        </div>
                        {/*
                        <div className="form-group">
                            <label htmlFor="fecha_i" className="col-form-label" >Inicio :</label>
                            <input 
                                type="date" 
                                name="fecha_i" 
                                className="form-control"
                                onChange={this.handleChange} 
                                value={this.state.fecha_i || ''} 
                                required 
                            />                          
                        </div>
                        <div className="form-group">
                            <label htmlFor="fecha_f" className="col-form-label" >Fin :</label>
                            <input 
                                type="date" 
                                name="fecha_f" 
                                className="form-control"
                                onChange={this.handleChange} 
                                value={this.state.fecha_f || ''} 
                                required 
                            />                          
                        </div>
                        */}
                        <div className="modal-footer">
                            <TextError show={error} text={error}/>
                            <button ref='btn' type="submit" className="btn btn-secondary" >Guardar</button>
                        </div>
                    </form>
                </div>

            </Fragment>
        )
    }
}

export default ModalBono