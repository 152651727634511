import { getArrayByTipo } from "../servicios"
import { HuertaCoreMatchOrden } from "./Ordenes"

export const calcularPorcentaje = (number,por) => {

    return ( por*number/100 )
    
}

export const calcularFees = (order,por) => {

    if(order.pay_t=="Efectivo"){
        return 0
    }else{
        return (por*order.amount_t/100)
    }
    
}

export const calcularTodas = (order,por) => {

    if(order.pay_t=="Efectivo"){
        return 0
    }else{
        return (por*order.amount_t/100)+500
    }
    
}

export const calcularDelivery = (productos) => {

    const snack=getArrayByTipo(productos,0)
    const core=getArrayByTipo(productos,1)
    const restaurante=getArrayByTipo(productos,2)
    const huerta=getArrayByTipo(productos,4)
    const cena=getArrayByTipo(productos,6)
    const match=HuertaCoreMatchOrden(huerta,core)

    let total=0

    if(snack.length>0){
        total+=parseFloat(snack[0].delivery)
    }

    if(restaurante.length>0){
        total+=parseFloat(restaurante[0].delivery)
    }

    if(cena.length>0){
        total+=parseFloat(cena[0].delivery)
    }

    if(match){
        total+=parseFloat(core[0].delivery)
        return total
    }

    if(core.length>0){
        total+=parseFloat(core[0].delivery)
    }

    if(huerta.length>0){
        total+=parseFloat(huerta[0].delivery)
    }

    return total
    
}

export const calcularGanaciaTotal = (orden) => {

    let total=0

    orden.productos.map((value)=>{
        total+=calcularPorcentaje(value.total,parseFloat(value.a_fee))
    })

    return total
    
}


