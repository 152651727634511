import * as moment from 'moment';
import 'moment/locale/es';
import { capitalizeWord } from './general';


export const getDiaMesEspanol = (date) => {

    let dia_name=moment(date,"YYYY-MM-DD").format('dddd')
    let dia=moment(date,"YYYY-MM-DD").format('DD')
    let mes=moment(date,"YYYY-MM-DD").format('MMMM')

    return capitalizeWord(dia_name)+" "+dia+" de "+mes
    
}

export const getDiaMesEspanolHuertaCongelado = (date) => {

    let dia_name=moment(date,"DD-MM-YYYY").format('dddd')
    let dia=moment(date,"DD-MM-YYYY").format('DD')
    let mes=moment(date,"DD-MM-YYYY").format('MMMM')

    return capitalizeWord(dia_name)+" "+dia+" de "+mes
    
}

export const getTodayDate= () => {

    return moment().format('DD-MM-YYYY')
    
}
