import React, { Component,Fragment } from 'react'
import { postData,putData,getData, allData } from '../../../servicios'
import empty from '../../../assets/images/empty.jpg'

export class ModalProductoPoco extends Component {

    constructor(props){
        super(props)
        this.state={
            price:props.param.price,
            name:props.param.name,
            desc:props.param.desc,
            aliado:props.param.aliado,
            //preview:props.param.image,
            category:props.param.category_id,
            categories:[],
            hall:props.param.hall_id,
            halls:[],
            dis:props.param.activo ? props.param.activo : 0,
            ally_id:props.param.ally_id,
            aliados:[],
            pri:props.param.pri,
            fee:props.param.ally_fee,
            if_offer:props.param.if_offer,
            price_offer:props.param.price_offer,
            stock:props.param.stock,
            patrones:props.param.patrones?props.param.patrones:[],
            default_p:props.param.patron_default,
            on_search:props.param?props.param.on_search:true
        }
        this.handleChange=this.handleChange.bind(this)
        this.handlePasillo=this.handlePasillo.bind(this)
    }

    handleChange(e){
        const { name,value,type}= e.target
        this.setState({
            [name]: value
        })
    }


    handleAliado = (e) => {
        const {value}= e.target
        this.setState({
            ally_id:value,
        })
    }
    

    handlePasillo(e){
        const { name,value}= e.target
        this.setState({
            [name]: value,
            hall: undefined
        },()=>{

            const {categories} =this.state
            const json=categories.filter((item)=>{
                return item.id==value
            })

            this.setState({
                halls:json[0].pasillos
            })

        })
    }

    handleFile=(e)=>{

        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                preview: reader.result
            });
        }
        reader.readAsDataURL(e.target.files[0])
    }

    submit=(e)=>{
        e.preventDefault()
        if(this.props.param.id){
            this.editar()
        }else{
            this.crear()
        }
        this.refs.btn.setAttribute("disabled", "disabled");
    }

    async crear(){

        const json={
            product:{
                name:this.state.name,
                desc:this.state.desc,
                price:this.state.price,
                category_id:this.state.category,
                status:1,
                aliado: this.state.aliados.find(ally=>ally.id==this.state.ally_id).name,
                activo: this.state.dis,
                ally_id:this.state.ally_id,
                pri:this.state.pri,
                tags:[],
                ally_fee:this.state.fee,
                if_offer:this.state.if_offer,
                price_offer:this.state.price_offer,
                stock:parseInt(this.state.stock)
            }
        }

        if(this.state.preview){
            json.product.image=this.state.preview
        }else{
            json.product.image=empty
        }

        if(this.state.hall){
            json.product.hall_id=this.state.hall
        }

        try{
            await postData("products",json)
            this.props.cargar()
            this.props.close()
        }catch(err){
            console.log(err)
        }


    }

    async editar(){
        const json={
            product:{
                name:this.state.name,
                desc:this.state.desc,
                price:this.state.price,
                category_id:this.state.category,
                image:this.state.preview,
                aliado: this.state.aliados.find(ally=>ally.id==this.state.ally_id).name,
                activo: this.state.dis,
                ally_id:this.state.ally_id,
                pri:this.state.pri,
                ally_fee:this.state.fee,
                if_offer:this.state.if_offer,
                price_offer:this.state.price_offer,
                stock:parseInt(this.state.stock),
                patrones:this.state.patrones,
                patron_default:this.state.default_p,
                on_search:this.state.on_search,
            }
        }

        if(this.state.hall){
            json.product.hall_id=this.state.hall
        }else{
            json.product.hall_id=null
        }

        try {
            await putData("products",this.props.param.id,json)
            this.props.cargar()
            this.props.close()
        } catch (err) {
            console.log(err)
        }

    }

    setPasillo = () => {

        const {category,categories} =this.state

        const json=categories.filter((item)=>{
            return item.id==category
        })

        if(json[0].pasillos.length>0){

            this.setState({
                halls:json[0].pasillos
            })
        }

    }


    async componentDidMount(){
        const response=await allData([getData("categories"),getData("allies")])
        const json=response[0].data
        const jsonAlly=response[1].data

        this.setState({
            categories:json,
            aliados:jsonAlly
        },()=>{

            //caso nuevo producto
            if(!this.props.param.id){

                this.setState({
                    category:json[0].id,
                    ally_id:jsonAlly[0].id
                },()=>{


                    this.setPasillo()

                })

            //caso producto viejo
            }else{


                this.setPasillo()

            }

        })

    }

    handleOfferCheck= (e) => {
        const { name,checked}= e.target
        this.setState({
            if_offer: checked
        })
    }

    handleCheck = (e) => {

        const {patrones}=this.state

        var index = patrones.indexOf(parseInt(e.target.value));
        if (e.target.checked){
            patrones.push(parseInt(e.target.value)); // push in array checked value
        }else{
            patrones.splice(index, 1); // removed in array unchecked value
        }

        this.setState({
            patrones:patrones
        })
        
    }

    handleSearchCheck= (e) => {
        const { name,checked}= e.target
        this.setState({
            on_search: checked
        })
    }



    render() {

        const { halls,aliados,categories,if_offer,patrones,on_search } = this.state;

        const list=categories.filter((value)=>value.pri<77).map((value)=>{
            return(
                <option key={value.id} value={value.id}>{value.name}</option>
            )
        })

        const list_especial=categories.filter((value)=>value.pri===77).map((value)=>{
            return(
                <option key={value.id} value={value.id}>{value.name}</option>
            )
        })

        const pasillos=halls.map((value)=>{
            return(
                <option key={value.id} value={value.id}>{value.name}</option>
            )
        })

        const list_aliados=aliados.map((value)=>{
            return(
                <option key={value.id} value={value.id}>{value.name}</option>
            )
        })

        let preview

        if(this.state.preview){
            preview=<img src={this.state.preview} alt="preview" className="center"/>
        }else{
            preview=<img src={empty} alt="preview" className="center"/>
        }

        return (
            <Fragment>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">Producto</h5>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.submit}>

                        {preview}
                        <div className="form-group">
                            <label htmlFor="image" className="col-form-label">Escoge una imagen :</label>
                            <input className="form-control" name="image" type="file" onChange={this.handleFile}  accept="image/jpeg" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name" className="col-form-label" >Nombre :</label>
                            <input type="text" name="name" className="form-control" onChange={this.handleChange} value={this.state.name || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="desc" className="col-form-label" >Descripción :</label>
                            <textarea rows="4" name="desc" className="form-control" onChange={this.handleChange} value={this.state.desc || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="category" className="col-form-label" >Categoria :</label>
                            <select name="category" onChange={this.handlePasillo} value={this.state.category || ''} className="form-control" required>

                                {list_especial}
                                <option disabled>──────────</option>
                                {list}

                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="hall" className="col-form-label" >Pasillo :</label>
                            <select name="hall" onChange={this.handleChange} value={this.state.hall || ''} className="form-control"  required>
                                <option  value={""}>N/A</option>
                                {pasillos}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="ally_id" className="col-form-label" >Aliado :</label>
                            <select name="ally_id" onChange={this.handleAliado} value={this.state.ally_id || ''} className="form-control"  required>
                                <option  value={""}>N/A</option>
                                {list_aliados}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="pri" className="col-form-label" >Prioridad :</label>
                            <input type="number" min="1" max="70" name="pri" className="form-control" onChange={this.handleChange} value={this.state.pri || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="stock" className="col-form-label" >Stock :</label>
                            <input type="number" min="0" name="stock" className="form-control" onChange={this.handleChange} value={this.state.stock || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dis" className="col-form-label" >Estado :</label>
                            <select name="dis" onChange={this.handleChange} value={this.state.dis || ''} className="form-control" required >
                                <option  value={0}>Agotado</option>
                                <option  value={1}>Disponible</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="fee" className="col-form-label" >Porcentaje :</label>
                            <input type="number" step={0.01} min="0" name="fee" className="form-control" onChange={this.handleChange} value={this.state.fee || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="price" className="col-form-label" >Precio :</label>
                            <input type="number" min="1" name="price" className="form-control" onChange={this.handleChange} value={this.state.price || ''} required />
                        </div>

                        <div className="tac my-2">
                            Patrones(Busqueda)
                        </div>

                        <div className="row">

                            <div className="col-md-4">
                                <div className="form-check">
                                    <input className="form-check-input" onChange={this.handleCheck} type="checkbox" value={0} checked={patrones.includes(0) ? true : false}/>
                                    <label className="form-check-label">
                                        Snack
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-check">
                                    <input className="form-check-input" onChange={this.handleCheck} type="checkbox" value={1} checked={patrones.includes(1) ? true : false}/>
                                    <label className="form-check-label">
                                        Mecado
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-check">
                                    <input className="form-check-input" onChange={this.handleCheck} type="checkbox" value={3} checked={patrones.includes(3) ? true : false}/>
                                    <label className="form-check-label">
                                        Plan alimenticio
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-check">
                                    <input className="form-check-input" onChange={this.handleCheck} type="checkbox" value={4} checked={patrones.includes(4) ? true : false}/>
                                    <label className="form-check-label">
                                        Huerta y congelados
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-check">
                                    <input className="form-check-input" onChange={this.handleCheck} type="checkbox" value={6} checked={patrones.includes(6) ? true : false}/>
                                    <label className="form-check-label">
                                        Restaurante
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div className="form-group">
                            <label htmlFor="default_p" className="col-form-label" >Patron de default :</label>
                            <select name="default_p" onChange={this.handleChange} value={this.state.default_p || ''} className="form-control" required >
                                <option  value={0}>Tipo Snack</option>
                                <option  value={1}>Tipo Core</option>
                                <option  value={3}>Tipo Plan</option>
                                <option  value={4}>Entrega Los Martes(Huerta Y Congelados)</option>
                                <option  value={6}>Restaurante</option>
                            </select>
                        </div>

                        <div className="form-group mt-3">
                            <label className="form-check-label" htmlFor="if_offer">Ver en busquedas</label>
                            <input name="if_offer" onChange={this.handleSearchCheck} 
                            className="check_offer ml-2" type="checkbox" checked={on_search? true : false}/>
                        </div>

                        <div className="form-group">
                            <label className="form-check-label" htmlFor="if_offer">Activar oferta</label>
                            <input name="if_offer" onChange={this.handleOfferCheck} 
                            className="check_offer ml-2" type="checkbox" checked={if_offer? true : false}/>
                        </div>

                        {
                            if_offer?
                            <div className="form-row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="price_offer">Precio de oferta</label>
                                    <input type="number" min={1} value={this.state.price_offer} className="form-control" name="price_offer" placeholder="Monto" onChange={this.handleChange} required />
                                </div>
                            </div>
                            :''
                        }
                        <div className="modal-footer">
                            <button ref='btn' type="submit" className="btn btn-secondary">Guardar</button>
                        </div>
                    </form>
                </div>

            </Fragment>
        )
    }
}

export default ModalProductoPoco