import React, { Component } from 'react'
import { getTotalPlan } from '../../../servicios'

class Plan extends Component {

    constructor(props){
        super(props)
        
    }


    render() {

        const {productos}=this.props

        if(productos.length>0){

            return (
                <div>
                    
                    <h5 className="f-w-600 center">Planes Alimenticios</h5>

                    <div className="row">
                        <div className="col-4">
                            <strong>Producto</strong>
                        </div>
                        <div className="col-8">
                            <strong>Costo</strong>                       
                        </div>
                    </div>
                    <br/>

                    {
                        productos.map((item, index) => {
                            return (

                                <div key={index}>

                                    <div className="row" >
                                        <div className="col-4">
                                            {item.producto} (×{item.amount})  
                                        </div>
                                        <div className="col-8">
                                            ${(item.total)}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <strong>Fecha De Entrega:</strong>
                                        </div>
                                        <div className="col-8">
                                            Del {item.fecha_i} al {item.fecha_f} a las {item.hora}                 
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4">
                                            <strong>Días Entrega:</strong>
                                        </div>
                                        <div className="col-8">
                                            {item.diasn}
                                        </div>
                                    </div>

                                    <br/>
                           

                                </div>
                            
                            )
                        })
                    }

                    
                    <div className="row">
                        <div className="col-4">
                            <strong>Delivery:</strong>
                        </div>
                        <div className="col-8">
                            ${productos[0].delivery}(Incluido en el precio del plan)              
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-4">
                            <strong>Total:</strong>
                        </div>
                        <div className="col-4">
                            ${getTotalPlan(productos)}             
                        </div>
                    </div>

                    <br/>
                 
                </div>
            )

        }else{
            return ''
        }
        
    }
}

export default Plan