import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import ModalProducto from './ModalProducto'
import { getData,deleteData } from '../../../servicios';
import ModalTag from './ModalTag';
import ReactExport from "react-data-export";
import { getTodayDate } from '../../../funciones/momentEspanol';
import ModalSearch from './ModalSearch';
import ModalHalls from './ModalHalls';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class Producto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openTag:false,
            openSearch:false,
            openHalls:false,
            data: [],
            searchInput: "",
            filteredData: [],
            loading:true,
        };
        this.globalSearch=this.globalSearch.bind(this)
        this.cargarTabla=this.cargarTabla.bind(this)
        this.eliminar=this.eliminar.bind(this)
    }

    onOpenModal = (param) => {
        this.setState({
            param: param,
            open:true
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onOpenTag = (param) => {
        this.setState({
            param: param,
            openTag:true
        });
    };

    onCloseTag = () => {
        this.setState({ 
            openTag: false 
        });
    };

    onOpenSearch = (param) => {
        this.setState({
            param: param,
            openSearch:true
        });
    };

    onCloseSearch = () => {
        this.setState({ 
            openSearch: false 
        });
    };

    onOpenHalls = (param) => {
        this.setState({
            param: param,
            openHalls:true
        });
    };

    onCloseHalls = () => {
        this.setState({ 
            openHalls: false 
        });
    };

    globalSearch(e) {
        let keys=["name","desc","price","category_name"]
        this.setState({
            searchInput: e.target.value
        })
    };

    filterArray = () => {

        let keys=["name","desc","price","category_name"]
        let { searchInput, data } = this.state;
        let filteredData = data.filter(value => {
            return (
                keys.reduce((acum,key)=>
                    {
                        return acum || value[key].toString().toLowerCase().includes(searchInput.toLowerCase())

                    },false
                )
            );
        })
        return filteredData

    }
    

    async cargarTabla(){

        const response=await getData("products")
        const json=response['data']

        this.setState({
            data:json,
            filteredData:json,
            loading:false,
        })

    }

    async componentDidMount(){
        this.cargarTabla()
    }

    async eliminar(id){
        if(window.confirm("Eliminar Producto?")){
            try {
                await deleteData("products",id)
                this.cargarTabla()
            } catch (err) {
                console.log(err)
            }
        }
    }

    render() {

        const { filteredData,open,param,openTag,openSearch,openHalls } = this.state;

        const list=this.filterArray().map((value)=>{

            return(
                <tr key={value.id}>
                    <td>
                        <img src={value.image} className="t-image"/>
                    </td>
                    <td>
                        {value.name}
                    </td>
                    <td>
                        {value.stock}
                    </td>
                    <td>
                        {value.desc}
                    </td>
                    <td>
                        {value.category_name}
                    </td>
                    <td>
                        {value.hall_name}
                    </td>
                    <td>
                        {value.price} $
                    </td>
                    <td>
                        %{value.ally_fee}
                    </td>
                    <td>
                        <span onClick={()=> this.onOpenModal(value)}>
                            <i className="fa fa-pencil pointer"  style={{ width: 35, fontSize: 20, padding: 11,color:'#138f9b' }}></i>
                        </span>
                        <span onClick={()=> this.onOpenTag(value)}>
                            <i className="fa fa-tag pointer" style={{ width: 35, fontSize: 20, padding: 11, color: '#138f9b' }}></i>
                        </span>
                        <span onClick={()=> this.onOpenSearch(value)}>
                            <i className="fa fa-search pointer" style={{ width: 35, fontSize: 20, padding: 11, color: '#138f9b' }}></i>
                        </span>
                        <span onClick={()=> this.onOpenHalls(value)}>
                            <i className="fa fa-list pointer" style={{ width: 35, fontSize: 20, padding: 11, color: '#138f9b' }}></i>
                        </span>
                        <span onClick={()=> this.eliminar(value.id)}>
                            <i className="fa fa-trash pointer" style={{ width: 35, fontSize: 20, padding: 11, color: '#b8112d' }}></i>
                        </span>
                    </td>
                </tr>
            )

        })

        return (
            <Fragment>

                <Breadcrumb title="Productos"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">

                                    <div className="btn-popup d-flex">
                                        <input placeholder="Buscar" onChange={this.globalSearch} className="form-control input-search mr-auto" />
                                        {
                                            this.state.loading?
                                            <></>
                                            :
                                            <ExcelFile 
                                                filename={"Lista de productos("+getTodayDate()+")"}
                                                element={<button type="button" className="btn btn-secondary pull-right mr-3">Exportar Excel</button>}>
                                                <ExcelSheet data={filteredData} name="Productos">
                                                    <ExcelColumn label="Prioridad" value="pri"/>
                                                    <ExcelColumn label="Nombre" value="name"/>
                                                    <ExcelColumn label="Stock" value="stock"/>
                                                    <ExcelColumn label="Categoria" value="category_name"/>
                                                    <ExcelColumn label="Precio" value="price"/>
                                                    <ExcelColumn label="% Aliado" value="ally_fee"/>
                                                </ExcelSheet>
                                            </ExcelFile>
                                        }
                                        <button type="button" className="btn btn-secondary pull-right" onClick={this.onOpenModal} data-toggle="modal" data-original-title="test" data-target="#exampleModal">Agregar Producto</button>
                                    </div>

                                    <Modal open={open} onClose={this.onCloseModal} >
                                        <ModalProducto param={param} close={this.onCloseModal} cargar={this.cargarTabla}/>
                                    </Modal>

                                    <Modal open={openTag} onClose={this.onCloseTag} >
                                        <ModalTag param={param} close={this.onCloseTag} cargar={this.cargarTabla}/>
                                    </Modal>

                                    <Modal open={openSearch} onClose={this.onCloseSearch} >
                                        <ModalSearch param={param} close={this.onCloseSearch} cargar={this.cargarTabla}/>
                                    </Modal>

                                    <Modal open={openHalls} onClose={this.onCloseHalls} >
                                        <ModalHalls param={param} close={this.onCloseHalls} cargar={this.cargarTabla}/>
                                    </Modal>

                                    <div className="user-status table-responsive latest-order-table">
                                        <table className="table table-bordernone">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Imagen</th>
                                                    <th scope="col">Nombre</th>
                                                    <th scope="col">Stock</th>
                                                    <th scope="col">Descripcion</th>
                                                    <th scope="col">Categoría</th>
                                                    <th scope="col">Pasillo</th>
                                                    <th scope="col">Precio</th>
                                                    <th scope="col">%</th>
                                                    <th scope="col" style={{width: '120px'}}>Accion</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

export default Producto
