import React, { Component } from 'react'
import Select from 'react-select';
import Loader from '../../common/Loader';
import { getData, postData } from '../../../servicios';

export class ModalProductoSugerido extends Component {

    constructor(props){
        super(props)
        this.state={
            loading:true,
            products:[],
            selection:[]
        }
    }


    async componentDidMount(){

        let array_products=[]


        
        try {

            const req=await getData("no-reco-products")

            req.data.map((value,i)=>{

                array_products.push(
                    {
                        value:value.id,
                        label:value.name
                    }
                )

            })
            this.setState({
                products:array_products,
                loading:false
            })

            
        } catch (error) {
            console.log(error)
        }
    }

    handleSelect=(e)=>{
        this.setState({
            selection: e
        })
    }

    handleSubmit = async (e) => {

        e.preventDefault()
        let array=[]
        
        this.state.selection.map((v)=>{
            array.push(v.value)
        })

        let json={
            ids:array
        }

        try {
            const req=await postData("/add-reco-pro",json)
            this.props.cargar()
            this.props.close()
        } catch (err) {
            console.log(err)
        }





        
    }
    


    render() {
        const {loading,products}=this.state
        console.log(this.state.selection)

        return (
            <div>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600">Productos Sugeridos</h5>
                </div>
                {
                    loading ?
                        <div className="tac">
                            <Loader/>
                        </div>                 
                    :
                    <div className="modal-body">

                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="selection" className="col-form-label">Selecciona los productos :</label>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={products}
                                    onChange={this.handleSelect}
                                />
                            </div>

                            <div className="modal-footer">
                                <button ref='btn' type="submit" className="btn btn-secondary" >Guardar</button>
                            </div>
                        </form>

                    </div>
                    
                }
            </div>
        )
    }
}

export default ModalProductoSugerido
