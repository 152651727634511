import React, { Component,Fragment } from 'react'
import { postData,putData,getData } from '../../../servicios'
import empty from '../../../assets/images/empty.jpg'

export class ModalAdicional extends Component {

    constructor(props){
        super(props)
        this.state={
            price:props.param.price,
            name:props.param.name,
            desc:props.param.desc,
            aliado:props.param.aliado,
            //preview:props.param.image,
            tipo:props.param.tipo,
            pri:props.param.pri,
            halls:[],
            hall:props.param.hall_id
        }
        this.handleChange=this.handleChange.bind(this)
    }

    handleChange(e){
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }


    handleFile=(e)=>{

        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                preview: reader.result
            });
        }
        reader.readAsDataURL(e.target.files[0])
    }

    submit=(e)=>{
        e.preventDefault()
        if(this.props.param.id){
            this.editar()
        }else{
            this.crear()
        }
        this.refs.btn.setAttribute("disabled", "disabled");
    }

    async crear(){

        const json={
            aggregate:{
                name:this.state.name,
                desc:this.state.desc,
                price:parseFloat(this.state.price),
                tipo:parseInt(this.state.tipo),
                pri: parseInt(this.state.pri),
                hall_id:parseInt(this.state.hall),
                status:0
            }
        }

        if(this.state.preview){
            json.aggregate.image=this.state.preview
        }else{
            json.aggregate.image=empty
        }


        console.log(json)

        try{
            await postData("aggregates",json)
            this.props.cargar()
            this.props.close()
        }catch(err){
            console.log(err)
        }


    }

    async editar(){
        const json={
            aggregate:{
                name:this.state.name,
                desc:this.state.desc,
                price:this.state.price,
                tipo:this.state.tipo,
                pri: this.state.pri,
                hall_id:parseInt(this.state.hall),
            }
        }

        if(this.state.preview){
            json.aggregate.image=this.state.preview
        }


        try {
            await putData("aggregates",this.props.param.id,json)
            this.props.cargar()
            this.props.close()
        } catch (err) {
            console.log(err)
        }

    }


    async componentDidMount(){

        try {

            //17 33 id de cena y almuerzos en produccion
            //17 y 34 id de cena y almuerzo en develop

            const req=await getData("halls")
            const pasillos=req.data.filter((value)=>{
                return (value.category_id==17 || value.category_id==33)
            })

            this.setState({
                halls:pasillos
            })
            
        } catch (error) {
            console.log(error)
        }

        if(!this.props.param.id){
            this.setState({
                tipo: 1,
            })
        }

    }

    render() {

        const { halls } = this.state;


        let preview

        if(this.state.preview){
            preview=<img src={this.state.preview} alt="preview" className="center"/>
        }else{
            preview=<img src={empty} alt="preview" className="center"/>
        }

        const pasillo_list=halls.map((value)=>{
            return(
                <option key={value.id} value={value.id}>{value.name}</option>
            )
        })



        return (
            <Fragment>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">Adicional</h5>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.submit}>

                        {preview}
                        <div className="form-group">
                            <label htmlFor="image" className="col-form-label">Escoge una imagen :</label>
                            <input className="form-control" name="image" type="file" onChange={this.handleFile}  accept="image/jpeg" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name" className="col-form-label" >Nombre :</label>
                            <input type="text" name="name" className="form-control" onChange={this.handleChange} value={this.state.name || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="desc" className="col-form-label" >Descripción :</label>
                            <textarea rows="4" name="desc" className="form-control" onChange={this.handleChange} value={this.state.desc || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="pri" className="col-form-label" >Prioridad :</label>
                            <input type="number" min="1" max="70" name="pri" className="form-control" onChange={this.handleChange} value={this.state.pri || ''} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="hall" className="col-form-label" >Pasillo :</label>
                            <select name="hall" onChange={this.handleChange} value={this.state.hall || ''} className="form-control" required>
                                <option  value={""}>N/A</option>
                                {pasillo_list}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="tipo" className="col-form-label" >Tipo de adicional :</label>
                            <select name="tipo" onChange={this.handleChange} value={this.state.tipo || ''} className="form-control" required >
                                <option  value={1}>Bebida</option>
                                <option  value={2}>Postre</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="price" className="col-form-label" >Precio :</label>
                            <input type="number" min="1" name="price" className="form-control" onChange={this.handleChange} value={this.state.price || ''} required />
                        </div>
                        <div className="modal-footer">
                            <button ref='btn' type="submit" className="btn btn-secondary">Guardar</button>
                        </div>
                    </form>
                </div>

            </Fragment>
        )
    }
}

export default ModalAdicional
