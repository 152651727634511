import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Modal from 'react-responsive-modal';
import ModalOrden from './ModalOrden'
import { getData,deleteData, putData } from '../../../servicios';
import ModalOrder from '../../Orden/ModalOrder';
import Swal from 'sweetalert2';
import ReactExport from "react-data-export";
import { getTodayDate } from '../../../funciones/momentEspanol';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class OrdenesActivas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: [],
            searchInput: "",
            filteredData: [],
            loading:true,
        };
        this.globalSearch=this.globalSearch.bind(this)
        this.cargarTabla=this.cargarTabla.bind(this)

    }

    onOpenModal = (param) => {
        this.setState({
            param: param,
            open:true
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    globalSearch(e) {
        let keys=["pedido","desc","cname","dir","email"]
        this.setState({
            searchInput: e.target.value
        },()=>{
            let { searchInput, data } = this.state;
            let filteredData = data.filter(value => {
                return (
                    keys.reduce((acum,key)=>

                        {
                            if(value[key]!=null){
                                return acum || value[key].toString().toLowerCase().includes(searchInput.toLowerCase())
                            }
                        },false


                    )
                );
            })
            console.log(filteredData)
            this.setState({ filteredData:filteredData });
        })
    };

    async cargarTabla(){

        const response=await getData("current-orders")
        const json=response['data']

        this.setState({
            data:json,
            filteredData:json,
            loading:false,
        })

    }

    async componentDidMount(){
        this.cargarTabla()
    }


    completarOrden = async (orden) => {

        const { value: option } = await Swal.fire({
            title: 'Deseas Actualizar la orden?',
            icon: 'question',
            input: 'select',
            inputOptions: {
                1: 'En Camino',
                2: 'Esperando envío',
                4: 'Compleado',
                6: 'Cancelado/Rechazado',
            },
            inputPlaceholder: 'Escoge una opción',
            showCancelButton: true,
            inputValidator: (value) => {
                return new Promise(async (resolve) => {

                    if(value){

                        const json={
                            order:{
                                status: parseInt(value),
                            }
                        }

                        try {
                            const response=await putData("orders",orden.id,json)
                            console.log(response)
                            this.cargarTabla()
                        } catch (err) {
                            console.log(err)
                        }
        
                        resolve()

                    }else{

                        resolve('Debes escoger una opción')

                    }

                    
    
                })
            }
        })
    
    }



    render() {

        const { filteredData,open,param } = this.state;

        const list=filteredData.map((value)=>{

            return(
                <tr key={value.id}>
                    <td>
                        {value.pedido}
                    </td>
                    <td>
                        {value.fecha}
                    </td>
                    <td>
                        {value.amount} $
                    </td>
                    <td>
                        {value.dir}
                    </td>
                    <td>
                        {value.cname}
                    </td>
                    <td>
                        {value.tel_c}
                    </td>
                    <td>
                        {value.email}
                    </td>
                    <td>
                        {value.pay_t}
                    </td>
                    <td>
                        {value.estado}
                    </td>
                    <td>
                        <span onClick={()=> this.onOpenModal(value)}>
                            <i className="fa fa-eye pointer"  style={{ width: 35, fontSize: 20, padding: 11,color:'#138f9b' }}></i>
                        </span>
                        <span onClick={()=> this.completarOrden(value)}>
                            <i className="fa fa-check pointer"  style={{ width: 35, fontSize: 20, padding: 11,color:'#138f9b' }}></i>
                        </span>
                    </td>

                </tr>
            )

        })

        return (
            <Fragment>

                <Breadcrumb title="Ordenes en Proceso"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">

                                    <div className="btn-popup d-flex">
                                        <input placeholder="Buscar" onChange={this.globalSearch} className="form-control input-search mr-auto" />
                                        {
                                            this.state.loading?
                                            <></>
                                            :
                                            <ExcelFile 
                                                filename={"Ordenes en proceso("+getTodayDate()+")"}
                                                element={<button type="button" className="btn btn-secondary pull-right mr-3">Exportar Excel</button>}>
                                                <ExcelSheet data={filteredData} name="Ordenes">
                                                    <ExcelColumn label="Pedido" value="pedido"/>
                                                    <ExcelColumn label="Fecha" value="fecha"/>
                                                    <ExcelColumn label="Monto" value="amount"/>
                                                    <ExcelColumn label="Dirección" value="dir"/>
                                                    <ExcelColumn label="Cliente" value="cname"/>
                                                    <ExcelColumn label="Teléfono" value="tel_c"/>
                                                    <ExcelColumn label="Correo" value="email"/>
                                                    <ExcelColumn label="Método de pago" value="pay_t"/>
                                                    <ExcelColumn label="Estado" value="estado"/>
                                                </ExcelSheet>
                                            </ExcelFile>
                                        }

                                    </div>

                                    <div className="user-status table-responsive latest-order-table">
                                        <table className="table table-bordernone">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Orden ID</th>
                                                    <th scope="col">Fecha y Hora</th>
                                                    <th scope="col">Orden Total</th>
                                                    <th scope="col">Dirección De Envío</th>
                                                    <th scope="col">Cliente</th>
                                                    <th scope="col">No. Contacto</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Método de pago</th>
                                                    <th scope="col">Estado</th>
                                                    <th scope="col">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal open={open} onClose={this.onCloseModal} >
                    <ModalOrder orden={param} close={this.onCloseModal} cargar={this.cargarTabla}/>
                </Modal>

            </Fragment>
        )
    }
}

export default OrdenesActivas
