import React, { Component, Fragment } from 'react';
import { postData, putData, getData } from '../../../servicios';
import empty from '../../../assets/images/empty.jpg';
import { cargarStorage, getLocalItem } from '../../../funciones/cargarDatos';

export class ModalPasillo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.param.name,
            desc: props.param.desc,
            //preview:props.param.image,
            category: props.param.category_id,
            categories: [],
            tipo: props.param.tipo,
            pri: props.param.pri,
            dis: props.param.activo ? props.param.activo : 0,
            ext: props.param.ext,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    handleFile = (e) => {
        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                preview: reader.result,
            });
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    submit = (e) => {
        e.preventDefault();
        if (this.props.param.id) {
            this.editar();
        } else {
            this.crear();
        }
        this.refs.btn.setAttribute('disabled', 'disabled');
    };

    async crear() {
        const json = {
            hall: {
                name: this.state.name,
                desc: this.state.desc,
                category_id: this.state.category,
                tipo: this.state.tipo,
                pri: this.state.pri,
                activo: this.state.dis,
                ext: this.state.ext,
            },
        };

        if (this.state.preview) {
            json.hall.image = this.state.preview;
        } else {
            json.hall.image = empty;
        }

        try {
            await postData('halls', json);
            cargarStorage();
            this.props.cargar();
            this.props.close();
        } catch (err) {
            console.log(err);
        }
    }

    async editar() {
        const json = {
            hall: {
                name: this.state.name,
                desc: this.state.desc,
                category_id: this.state.category,
                image: this.state.preview,
                tipo: this.state.tipo,
                pri: this.state.pri,
                activo: this.state.dis,
                ext: this.state.ext,
            },
        };

        console.log(json);

        try {
            await putData('halls', this.props.param.id, json);
            cargarStorage();
            this.props.cargar();
            this.props.close();
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidMount() {
        const data = getLocalItem('categories') ? getLocalItem('categories') : [];
        this.setState({
            categories: data,
        });

        if (!this.props.param.id) {
            this.setState({
                category: data[0].id,
                tipo: 0,
                cities: 1,
                ext: 0,
            });
        }
    }

    render() {
        const { categories } = this.state;

        const list = categories
            .filter((value) => value.pri < 77)
            .map((value) => {
                return (
                    <option key={value.id} value={value.id}>
                        {value.name}
                    </option>
                );
            });

        const list_especial = categories
            .filter((value) => value.pri === 77)
            .map((value) => {
                return (
                    <option key={value.id} value={value.id}>
                        {value.name}
                    </option>
                );
            });

        let preview;

        if (this.state.preview) {
            preview = <img src={this.state.preview} alt="preview" className="center" />;
        } else {
            preview = <img src={empty} alt="preview" className="center" />;
        }

        return (
            <Fragment>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">
                        Pasillo
                    </h5>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.submit}>
                        {preview}
                        <div className="form-group">
                            <label htmlFor="image" className="col-form-label">
                                Escoge una imagen :
                            </label>
                            <input
                                className="form-control"
                                name="image"
                                type="file"
                                onChange={this.handleFile}
                                accept="image/jpeg"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name" className="col-form-label">
                                Nombre :
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.name || ''}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="desc" className="col-form-label">
                                Descripción :
                            </label>
                            <textarea
                                rows="4"
                                name="desc"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.desc || ''}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="pri" className="col-form-label">
                                Prioridad :
                            </label>
                            <input
                                type="number"
                                min="1"
                                max="100"
                                name="pri"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.pri || ''}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="cities" className="col-form-label">
                                Ciudades de envío :
                            </label>
                            <select
                                name="ext"
                                onChange={this.handleChange}
                                value={this.state.ext || ''}
                                className="form-control"
                                required
                            >
                                <option value={0}>Todas</option>
                                <option value={1}>Bogotá/Chía</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="category" className="col-form-label">
                                Categoria :
                            </label>
                            <select
                                name="category"
                                onChange={this.handleChange}
                                value={this.state.category || ''}
                                className="form-control"
                                required
                            >
                                {list_especial}
                                <option disabled>──────────</option>
                                {list}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="tipo" className="col-form-label">
                                Patron de envío :
                            </label>
                            <select
                                name="tipo"
                                onChange={this.handleChange}
                                value={this.state.tipo || ''}
                                className="form-control"
                            >
                                <option value={0}>N/A</option>
                                <option value={1}>Programable Semanal</option>
                                <option value={2}>Programable Quincenal</option>
                                <option value={4}>Programable Mensual</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="dis" className="col-form-label">
                                Estado :
                            </label>
                            <select
                                name="dis"
                                onChange={this.handleChange}
                                value={this.state.dis || ''}
                                className="form-control"
                                required
                            >
                                <option value={0}>Cerrado</option>
                                <option value={1}>Disponible</option>
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button ref="btn" type="submit" className="btn btn-secondary">
                                Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </Fragment>
        );
    }
}

export default ModalPasillo;
