import React, { Component } from 'react'
import { cargarStorage, getLocalItem } from '../../../funciones/cargarDatos'
import { getData, putData } from '../../../servicios'
import Loader from '../../common/Loader'

export class ModalHalls extends Component {


    constructor(props){
        super(props)
        this.state={
            list:props.param.halls,
            open_semana:'',
            open_fin:'',
            close_semana:'',
            close_fin:'',
            halls:[],
            loading:true
        }
    }

    submit = async (e) => {
        e.preventDefault()

        const json={
            product:{
                halls: this.state.list
            }
        }

        try {
            const req=await putData("products",this.props.param.id,json)
            console.log(req)
            this.props.cargar()
            this.props.close()
        } catch (err) {
            console.log(err)
        }

    }

    handleCheck = (e) => {

        const {list}=this.state

        var index = list.indexOf(parseInt(e.target.value));
        if (e.target.checked){
            list.push(parseInt(e.target.value)); // push in array checked value
        }else{
            list.splice(index, 1); // removed in array unchecked value
        }

        this.setState({
            list:list
        })
        
    }


    handleChange=(e)=>{
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    async componentDidMount(){
        
        try {

            const halls=getLocalItem('halls')?getLocalItem('halls'):[]

            const sortData=halls.sort(function(a, b){
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            })

            this.setState({
                halls:sortData,
                loading:false
            })

            
        } catch (error) {
            console.log(error)
        }
    }

    render() {

        const {list,loading,halls}= this.state

        const list_check=halls.map((value,i)=>{

            return (
                <div className="col-md-6" key={i}>
                    <div className="form-check">
                        <input className="form-check-input" onChange={this.handleCheck} type="checkbox" value={value.id} checked={list.includes(value.id) ? true : false}/>
                        <label className="form-check-label">
                            {value.name}
                        </label>
                    </div>
                </div>
            )
        })

        return (
            <div>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">Pasillos</h5>
                </div>
                {
                    loading ?
                        <div className="tac">
                            <Loader/>
                        </div>                 
                    :
                    <div className="modal-body">

                        <form onSubmit={this.submit} >

                            <div className="row">
                                {list_check}
                            </div>
                     
                            <div className="modal-footer">
                                <button ref='btn' type="submit" className="btn btn-secondary" >Guardar</button>
                            </div>

                        </form>

                    </div>

                }
                
            </div>
        )
    }
}

export default ModalHalls