import React, { Component,Fragment } from 'react'
import { postData,putData,getData, allData } from '../../../servicios'
import empty from '../../../assets/images/empty.jpg'

export class ModalSearch extends Component {

    constructor(props){
        super(props)
        this.state={
            key_words:props.param.key_words,
        }
        this.handleChange=this.handleChange.bind(this)
    }

    handleChange(e){
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }



    handleFile=(e)=>{

        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                preview: reader.result
            });
        }
        reader.readAsDataURL(e.target.files[0])
    }

    submit=(e)=>{
        e.preventDefault()
        if(this.props.param.id){
            this.editar()
        }else{
            this.crear()
        }
        this.refs.btn.setAttribute("disabled", "disabled");
    }

    async editar(){
        const json={
            product:{
                key_words:this.state.key_words
            }
        }



        try {
            await putData("products",this.props.param.id,json)
            this.props.cargar()
            this.props.close()
        } catch (err) {
            console.log(err)
        }

    }



    async componentDidMount(){

    }


    render() {

        const { } = this.state;

        return (
            <Fragment>
                <div className="modal-header bg-secondary">
                    <h5 className="white modal-title f-w-600" id="exampleModalLabel2">{this.props.param.name}</h5>
                </div>
                <div className="modal-body">
                    <form onSubmit={this.submit}>


                        <div className="form-group">
                            <label htmlFor="key_words" className="col-form-label" >Palabras clave :</label>
                            <textarea
                                rows={10}
                                type="text" 
                                name="key_words" 
                                className="form-control" 
                                onChange={this.handleChange} 
                                value={this.state.key_words || ''} 
                                required 
                            />
                        </div>
                        
                        <div className="modal-footer">
                            <button ref='btn' type="submit" className="btn btn-secondary">Guardar</button>
                        </div>
                    </form>
                </div>

            </Fragment>
        )
    }
}

export default ModalSearch